import React, { useEffect, useState } from "react";
import { queryAllDocsByFieldSnap } from "../../../utils/firebase.utils";
import { useSelector } from "react-redux";
import ReservationCard from "./ReservationCard";

export default function MyReservations() {
  const currentUser = useSelector((state) => state.userObject);
  const [reservations, setReservations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchdata = async () => {
      await queryAllDocsByFieldSnap(
        "bookings",
        "createdBy",
        currentUser.uid,
        (data) => {
          //sort by createdAt
          data.sort((a, b) => {
            if (a.createdAt < b.createdAt) {
              return 1;
            }
            if (a.createdAt > b.createdAt) {
              return -1;
            }
            return 0;
          });
          setReservations(data);
          setIsLoading(false);
        }
      );
    };
    fetchdata();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div className="header">My Reservations</div>

      <div className="mt-4">
        <div>
          <div className="flex items-center gap-4">
            <div className="w-48 font-bold">Room Name</div>
            <div className="w-48 font-bold">Date of Request</div>
            <div className="w-48 font-bold">Reservation Date</div>
            <div className="w-48 font-bold">Start Time</div>
            <div className="w-48 font-bold">End Time</div>
            <div className="w-48 font-bold">Status</div>
          </div>
        </div>
        {reservations.map((res, index) => (
          <div key={index} className="card mt-2">
            <ReservationCard data={res} />
          </div>
        ))}
      </div>
    </div>
  );
}
