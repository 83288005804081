import React, { useEffect, useState } from "react";
import StarRating from "./StarRating";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import DayViewCalendar from "../DayViewCalendar";
import { useSelector } from "react-redux";
import { getAllDocsSnap, addNewDoc } from "../../../utils/firebase.utils";
import { useNavigate } from "react-router-dom";
import { convertTimeStamptoDate } from "../../../utils/PipolUtils";
import toast from "react-hot-toast";
import { Timestamp } from "firebase/firestore";

export default function ReservationPage({ data, setViewMode }) {
  const currentUser = useSelector((state) => state.userObject);
  const [isProcessing, setIsProcessing] = useState(false);
  const [existingBooking, setExistingBooking] = useState([]);
  const [dateSelected, setDateSelected] = useState(null);
  const [bookings, setBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    date: Timestamp.fromDate(new Date()),
    startTime: "9:00",
    endTime: "12:00",
    bookingType: "proposed",
    roomName: data.name,
    createdAt: new Date(),
    createdBy: currentUser.uid,
    createdName: "Proposed",
    createdEmail: currentUser.email,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchBookings = async () => {
      await getAllDocsSnap("bookings", (snap) => {
        const filteredData = snap.filter((item) => item.roomId === data.id);
        setExistingBooking(filteredData);
        setBookings(filteredData);
        setIsLoading(false);
      });
    };
    fetchBookings();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const isOverlapping = () => {
    const startTime = new Date(formData.date);
    const endTime = new Date(formData.date);
    startTime.setHours(Number(formData.startTime.split(":")[0]));
    startTime.setMinutes(Number(formData.startTime.split(":")[1]));
    endTime.setHours(Number(formData.endTime.split(":")[0]));
    endTime.setMinutes(Number(formData.endTime.split(":")[1]));

    for (let i = 0; i < existingBooking?.length; i++) {
      const existingStart = new Date(
        convertTimeStamptoDate(existingBooking[i].date)
      );
      const existingEnd = new Date(
        convertTimeStamptoDate(existingBooking[i].date)
      );
      existingStart.setHours(
        Number(existingBooking[i].startTime.split(":")[0])
      );
      existingStart.setMinutes(
        Number(existingBooking[i].startTime.split(":")[1])
      );
      existingEnd.setHours(Number(existingBooking[i].endTime.split(":")[0]));
      existingEnd.setMinutes(Number(existingBooking[i].endTime.split(":")[1]));

      if (
        (startTime >= existingStart && startTime < existingEnd) ||
        (endTime > existingStart && endTime <= existingEnd) ||
        (startTime <= existingStart && endTime >= existingEnd)
      ) {
        return true;
      }
    }
    return false;
  };

  const handleCalendarChange = (date) => {
    setDateSelected(date);
    setFormData({ ...formData, date: date });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //   check if booking is overlapping to any existing booking

  const handleBooking = async () => {
    if (isOverlapping()) {
      toast.error("Selected Date and Time is not available for booking");
      return;
    }

    setIsProcessing(true);

    const docObject = {
      ...formData,
      date: dateSelected,
      roomId: data.id,
      bookingType: "booked",
      roomName: data.name,
      createdAt: new Date(),
      createdBy: currentUser.uid,
      createdName: currentUser.displayName,
      createdEmail: currentUser.email,
      status: "Pending",
    };

    await addNewDoc("bookings", docObject);
    toast.success("Booking Successful");
    setIsProcessing(false);
    setViewMode(false);
    navigate("/meeting-room");
  };

  const proposed = {
    date: Timestamp.fromDate(new Date(dateSelected)),
    startTime: formData.startTime,
    endTime: formData.endTime,
    bookingType: "your booking",
    createdName: "Proposed",
  };

  return (
    <div className="page relative">
      <button className="close-button" onClick={() => setViewMode(false)}>
        Close
      </button>
      <div className="text-lg font-semibold text-blue-900">
        Reservation for {data.name}
      </div>

      <div className="flex items-start gap-4 mt-4  flex-col lg:flex-row">
        <div className="flex flex-col items-start gap-8 flex-wrap w-96">
          <div>
            <div className="">
              <div className="header">Photos</div>
              <img
                src={data.primaryPhoto}
                className="h-72 w-72 object-cover rounded-md"
                alt=""
              />
            </div>
            <StarRating rating={data.rating || 5} />
          </div>
          <div>
            <div className="header">Features</div>
            <div className="mt-4 w-96">
              {data.features.map((feature, index) => (
                <div
                  className="flex items-center justify-between mt-2"
                  key={index}
                >
                  <div className="font-bold text-blue-900 w-60">
                    {feature.name}:
                  </div>
                  <div className="w-60">{feature.value}</div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="w-full lg:w-fit">
          <div className="flex items-center gap-4 flex-wrap justify-center flex-col">
            <div className="header">Select Date Below</div>
            {!dateSelected && (
              <Calendar onChange={handleCalendarChange} className={"w-96"} />
            )}
            {dateSelected && (
              <DayViewCalendar
                date={dateSelected}
                setDateSelected={setDateSelected}
                bookings={bookings}
                proposed={proposed}
              />
            )}

            <div className="flex items-center justify-center gap-8">
              <div className="mt-2 text-gray-600 text-sm">
                <div className="font-bold">Start Time</div>
                <input
                  className="border border-gray-400 text-eleghoGreen rounded-sm p-2"
                  type="time"
                  defaultValue={"09:00"}
                  name="startTime"
                  onChange={handleChange}
                />
              </div>
              <div className="mt-2 text-gray-600 text-sm">
                <div className="font-bold">End Time</div>
                <input
                  className="border border-gray-400 text-eleghoGreen rounded-sm p-2"
                  type="time"
                  defaultValue={"12:00"}
                  name="endTime"
                  onChange={handleChange}
                />
              </div>
            </div>

            {dateSelected && (
              <div className="">
                <div className="mt-4">
                  <div>Meeting Type</div>
                </div>
                <button
                  className="blue-button"
                  onClick={handleBooking}
                  disabled={isProcessing}
                >
                  Submit
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
