import React from "react";
import { FaCloudArrowUp } from "react-icons/fa6";
import { useRef } from "react";
import { CiSquarePlus } from "react-icons/ci";

export default function PhotosUploader({
  data,
  setData,
  primaryPhoto,
  setPrimaryPhoto,
  uploader,
  disabled,
}) {
  const photoRef = useRef();
  const handleRemove = (index) => {
    //remove the photo from the formData with the index number
    const newPhotos = data.filter((photo, i) => i !== index);
    setData(newPhotos);
  };

  return (
    <div>
      <input
        type="file"
        ref={photoRef}
        className="hidden"
        accept="image/*"
        multiple
        onChange={uploader}
        disabled={disabled}
      />
      <div className="border-2 border-dashed  border-gray-400 p-8 rounded-lg bg-white flex items-center justify-center gap-4 mt-4">
        <div className="flex flex-col items-center">
          <div className="flex items-center gap-4 flex-wrap mb-4">
            {data && data.length > 0
              ? data.map((image, index) => (
                  <div>
                    <button
                      type="button"
                      onClick={() => setPrimaryPhoto(image)}
                      disabled={disabled}
                    >
                      {primaryPhoto === image ? "Primary" : "Set as Primary"}
                    </button>
                    <img
                      src={image}
                      alt="property"
                      className="w-20 h-20 object-cover"
                    />
                    <button
                      className="underline mt-2 text-gray-400 text-xs text-center"
                      onClick={() => handleRemove(index)}
                      type="button"
                      disabled={disabled}
                    >
                      Remove
                    </button>
                  </div>
                ))
              : null}
          </div>
          <FaCloudArrowUp />
          <div className="text-sm font-semibold text-eleghoBlue">
            Upload Sample Photos
          </div>
          <button
            type="button"
            className="text-2xl cursor-pointer hover:bg-gray-400 p-2 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={disabled}
            onClick={() => photoRef.current.click()}
          >
            <CiSquarePlus />
          </button>
        </div>
      </div>
    </div>
  );
}
