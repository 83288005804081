import React from "react";
import { BsStar } from "react-icons/bs";
import { BsStarFill } from "react-icons/bs";
import { BsStarHalf } from "react-icons/bs";

const Star = ({ type }) => {
  const StarIcon =
    type === "filled" ? BsStarFill : type === "half" ? BsStarHalf : BsStar;
  return (
    <StarIcon style={{ width: "20x", height: "20px", marginRight: "2px" }} />
  );
};

export default function StarRating({ rating }) {
  const stars = [];

  for (let i = 1; i <= 5; i++) {
    if (i <= Math.floor(rating)) {
      stars.push(<Star key={i} type="filled" />);
    } else if (i === Math.ceil(rating) && rating % 1 !== 0) {
      stars.push(<Star key={i} type="half" />);
    } else {
      stars.push(<Star key={i} type="empty" />);
    }
  }

  return (
    <div
      style={{ display: "flex" }}
      className="text-yellow-500 "
    >
      {stars} <div className="ml-2">{rating}</div>
    </div>
  );
}
