import React from "react";
import { MessageSquare, Paperclip, Calendar, Clock, User } from "lucide-react";

export default function RequestCard({ request }) {
  const getStatusBadgeStyle = (status) => {
    const baseStyle = "text-xs font-medium px-2.5 py-1 rounded-full shadow-sm";
    switch (status.toLowerCase()) {
      case "approved":
        return `${baseStyle} bg-green-500 text-white`;
      case "pending":
        return `${baseStyle} bg-leechiuOrange text-white`;
      case "rejected":
        return `${baseStyle} bg-red-500 text-white`;
      default:
        return `${baseStyle} bg-gray-500 text-white`;
    }
  };

  return (
    <div className="relative overflow-hidden rounded-xl bg-gradient-to-br from-gray-50 via-white to-gray-50 p-4 transition-all duration-300 hover:from-blue-50 hover:via-white hover:to-orange-50">
      {/* Header */}
      <div className="flex justify-between items-start gap-4 mb-3">
        <div>
          <h3 className="font-bold text-gray-900">
            {request.requestType}
            {request.requestType === "Others" && (
              <span className="text-gray-600 font-normal ml-1">
                - {request.description}
              </span>
            )}
          </h3>
          <p className="text-sm text-gray-600 line-clamp-1 mt-0.5">
            {request.purpose}
          </p>
        </div>
        <span className={getStatusBadgeStyle(request.status)}>
          {request.status}
        </span>
      </div>

      {/* Info Section */}
      <div className="grid grid-cols-2 gap-2 mb-3">
        <div className="flex items-center text-sm text-gray-600">
          <User className="w-4 h-4 text-leechiuBlue mr-1.5 stroke-2" />
          <span className="truncate">{request.requestedBy}</span>
        </div>
        <div className="flex items-center text-sm text-gray-600">
          <Calendar className="w-4 h-4 text-leechiuOrange mr-1.5 stroke-2" />
          <span className="truncate">{request.dateRequired}</span>
        </div>
      </div>

      {/* Footer */}
      <div className="flex items-center justify-between pt-2 border-t border-gray-100">
        <div className="flex items-center text-sm text-gray-600">
          <Clock className="w-4 h-4 text-gray-400 mr-1.5 stroke-2" />
          <span className="truncate">{request.dateRequested}</span>
        </div>
        <div className="flex items-center gap-3">
          {request.comments && (
            <div className="flex items-center gap-1 text-sm text-gray-500 hover:text-leechiuBlue transition-colors">
              <MessageSquare className="w-4 h-4 stroke-2" />
              <span>{request.comments?.length}</span>
            </div>
          )}
          {request.file && request.file !== "null" && (
            <div className="flex items-center gap-1 text-sm text-gray-500 hover:text-leechiuOrange transition-colors">
              <Paperclip className="w-4 h-4 stroke-2" />
              <span>1</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
