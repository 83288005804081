import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getDocDataSnap,
  setDocData,
  storage,
  deleteDocById,
} from "../../utils/firebase.utils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  FiPaperclip,
  FiMessageCircle,
  FiEdit2,
  FiTrash2,
} from "react-icons/fi";

import SelectBox from "../../components/SelectBox";

export default function UserRequestDetails() {
  const { id } = useParams();
  const [request, setRequest] = useState({});
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [comment, setComment] = useState("");
  const currentUser = useSelector((state) => state.userObject);
  const [isProcessing, setIsProcessing] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [status, setStatus] = useState("");
  const [progress, setProgress] = useState(0);
  const [file, setFile] = useState("null");

  const navigate = useNavigate();

  useEffect(() => {
    const getRequest = async () => {
      const request = await getDocDataSnap("requests", id, (request) => {
        setStatus(request.status);
        setFile(request.file);
        setRequest(request);
        setComments(request.comments || []);
        setLoading(false);
      });
    };
    getRequest();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleAddComment = async (e) => {
    setIsProcessing(true);

    const newComments = [
      ...comments,
      {
        comment,
        commentBy: currentUser.displayName,
        commentByEmail: currentUser.email,
        date: new Date().toLocaleString(),
      },
    ];

    await setDocData("requests", id, { comments: newComments });
    setComments(newComments);
    setComment("");
    setIsProcessing(false);
    toast.success("Comment added successfully");
  };

  const handleEdit = async () => {
    setEditMode(!editMode);

    if (editMode) {
      const docObject = {
        ...request,
        status: status,
        updatedBy: currentUser.displayName,
        updatedByEmail: currentUser.email,
        updatedAt: new Date().toLocaleString(),
        // file: file ? file : request.file,
      };
      await setDocData("requests", id, docObject);
      handleSendEmailNotification();
      toast.success("Request updated successfully");
    }
  };

  const handleSendEmailNotification = async () => {
    const recipients = ["aidi.tia@leechiu.com", "maricor.sabangan@leechiu.com"];
    const docObject = {
      requestor: recipients,
      recipientName: request.requestedBy,
      recipientEmail: request.requestedByEmail,
      subject: "HR Request Status Updated" + " - " + request.requestType,
      message: `Your HR request has been updated to ${status}. Please check the HR Requests page for more details.`,
      link: "https://myhr.leechiu.app/requests",
    };

    const sendEmail = async () => {
      await fetch("https://hrms-emailerv1.vercel.app/api/notifications", {
        method: "POST",
        body: JSON.stringify(docObject),
      });
    };
    sendEmail();
  };

  const statusOptions = [
    { value: "pending", label: "Pending" },
    { value: "approved", label: "Approved" },
    { value: "rejected", label: "Rejected" },
  ];

  const handleSelect = async (selectedOption) => {
    setStatus(selectedOption.value);
  };

  const handleUpload = async (e) => {
    const file = e.target.files[0];
    const storageRef = ref(storage, `files/requests/${id}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(progress);
        switch (snapshot.state) {
          case "paused":
            break;
          case "running":
            break;
        }
      },
      (error) => {},
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          await setDocData("requests", id, { file: downloadURL });
          toast.success("File uploaded successfully");
        });
      }
    );
  };

  const handleDelete = async () => {
    if (window.confirm("Are you sure you want to delete this request?")) {
      await deleteDocById("requests", id);
      toast.success("Request deleted successfully");
    }
    navigate("/requests");
  };

  return (
    <div className="max-w-5xl mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-8">
        <h1 className="header">Request Details</h1>
        {request.requestedByEmail === currentUser.email &&
          request.status === "pending" && (
            <button
              onClick={handleDelete}
              className="flex items-center gap-2 px-4 py-2 text-red-600 hover:bg-red-50 rounded-lg transition-all"
            >
              <FiTrash2 /> Delete Request
            </button>
          )}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2">
          <div className="bg-white rounded-xl shadow-sm p-6 space-y-6">
            <div className="flex items-center gap-3 mb-6">
              <div
                className={`px-4 py-1.5 rounded-full text-sm font-medium ${
                  status === "approved"
                    ? "bg-green-100 text-green-700"
                    : status === "rejected"
                    ? "bg-red-100 text-red-700"
                    : "bg-yellow-100 text-yellow-700"
                }`}
              >
                {request.status}
              </div>
              {currentUser.roles.includes("hr maker") && (
                <button
                  className="flex items-center gap-2 text-leechiuBlue hover:text-leechiuBlue/80 transition-colors"
                  onClick={handleEdit}
                >
                  <FiEdit2 size={16} />
                  {editMode ? "Save Changes" : "Edit Request"}
                </button>
              )}
            </div>

            <div className="space-y-4">
              <div className="grid grid-cols-2 gap-4 p-4 bg-gray-50 rounded-lg">
                <div>
                  <div className="text-sm text-gray-600">Requested by</div>
                  <div className="font-medium">{request.requestedBy}</div>
                </div>
                <div>
                  <div className="text-sm text-gray-600">Type of Request</div>
                  <div className="font-medium">
                    {request.requestType}
                    {request.requestType === "Others" &&
                      ` - ${request.description}`}
                  </div>
                </div>
                <div>
                  <div className="text-sm text-gray-600">Purpose</div>
                  <div className="font-medium">{request.purpose}</div>
                </div>
                <div>
                  <div className="text-sm text-gray-600">Date Required</div>
                  <div className="font-medium">{request.dateRequired}</div>
                </div>
                <div>
                  <div className="text-sm text-gray-600">Date Requested</div>
                  <div className="font-medium">{request.dateRequested}</div>
                </div>
                <div>
                  <div className="text-sm text-gray-600">Attachments</div>
                  <div className="font-medium">
                    {!request.file || request.file === "null" ? (
                      <span className="text-gray-400">No file uploaded</span>
                    ) : (
                      <a
                        href={request.file}
                        target="_blank"
                        rel="noreferrer"
                        className="flex items-center gap-2 text-leechiuBlue hover:text-leechiuBlue/80"
                      >
                        <FiPaperclip />
                        View File
                      </a>
                    )}
                  </div>
                </div>
              </div>

              {currentUser.roles.includes("hr maker") && editMode && (
                <div className="mt-6 space-y-4">
                  <div className="w-full max-w-xs">
                    <SelectBox
                      disabled={!editMode}
                      name="status"
                      label="Update Status"
                      placeholder="Select Status"
                      options={statusOptions}
                      handleSelect={handleSelect}
                      value={statusOptions.find(
                        (option) => option.value === status
                      )}
                    />
                  </div>

                  <div className="space-y-2">
                    <label className="text-sm text-gray-600">
                      Update Attachment
                    </label>
                    <input
                      type="file"
                      onChange={handleUpload}
                      className="w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-medium file:bg-leechiuBlue/10 file:text-leechiuBlue hover:file:bg-leechiuBlue/20"
                    />
                    {progress > 0 && (
                      <div className="w-full bg-gray-200 rounded-full h-2.5">
                        <div
                          className="bg-leechiuBlue h-2.5 rounded-full transition-all"
                          style={{ width: `${progress}%` }}
                        ></div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="lg:col-span-1">
          <div className="bg-white rounded-xl shadow-sm p-6">
            <div className="flex items-center gap-2 mb-6">
              <FiMessageCircle className="text-leechiuBlue" size={20} />
              <h2 className="text-lg font-medium">Comments</h2>
            </div>

            <div className="space-y-4 mb-6">
              {comments.length > 0 ? (
                comments.map((comment, index) => (
                  <div
                    key={index}
                    className="p-4 bg-gray-50 rounded-lg space-y-2"
                  >
                    <div className="flex items-center justify-between">
                      <div className="font-medium">{comment.commentBy}</div>
                      <div className="text-xs text-gray-500">
                        {comment.date}
                      </div>
                    </div>
                    <div className="text-gray-600">{comment.comment}</div>
                  </div>
                ))
              ) : (
                <div className="text-center text-gray-500 py-4">
                  No comments yet
                </div>
              )}
            </div>

            <div className="space-y-4">
              <textarea
                name="comment"
                rows={3}
                className="w-full rounded-lg px-4 py-3 border border-gray-200 focus:ring-2 focus:ring-leechiuBlue focus:border-transparent transition-all resize-none"
                placeholder="Add your comment..."
                onChange={(e) => setComment(e.target.value)}
                value={comment}
              ></textarea>

              <button
                className="w-full bg-leechiuBlue hover:bg-leechiuBlue/90 text-white font-medium px-4 py-2 rounded-lg transition-colors disabled:opacity-50"
                onClick={handleAddComment}
                disabled={isProcessing || !comment.trim()}
              >
                {isProcessing ? "Adding Comment..." : "Add Comment"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
