import React, { useEffect } from "react";
import BentoContainer from "./BentoContainer";

export default function BentoTable({
  label,
  children,
  data,
  numberOfItemPerPage,
  currentPage,
  setCurrentPage,
  setSearchTerm,
}) {
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  const numberOfPages = Math.ceil(data.length / numberOfItemPerPage);

  const handleNext = () => {
    if (currentPage < numberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <BentoContainer label={label}>{children}</BentoContainer>

      <div className="mt-4 flex items-center justify-between">
        <button
          className="blue-button"
          disabled={currentPage === 1}
          onClick={handlePrevious}
        >
          Previous
        </button>

        <div className="flex items-center gap-2">
          {Array(numberOfPages)
            .fill()
            .map((_, index) => (
              <button
                className={
                  currentPage === index + 1 ? "active-pill" : "inactive-pill"
                }
                key={index}
                onClick={() => handlePagination(index + 1)}
              >
                {index + 1}
              </button>
            ))}
        </div>

        <button
          disabled={currentPage === numberOfPages}
          className="blue-button"
          onClick={handleNext}
        >
          Next
        </button>
      </div>
    </div>
  );
}
