import React, { useEffect, useState } from "react";
import {
  Day,
  Inject,
  Month,
  ScheduleComponent,
  ViewDirective,
  ViewsDirective,
  Week,
  WorkWeek,
} from "@syncfusion/ej2-react-schedule";
import { registerLicense } from "@syncfusion/ej2-base";
import { convertTimeStamptoDate } from "../../utils/PipolUtils";

registerLicense(
  "ORg4AjUWIQA/Gnt2XVhhQlJHfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTH5RdkdjX35ddHNSRWNY"
);

export default function DayViewCalendar({
  proposed,
  date,
  setDateSelected,
  bookings,
}) {
  const newBookingArray = [...bookings, proposed];

  const categoryColors = [
    {
      type: "booked",
      color: "#4635B1",
    },
    {
      type: "your booking",
      color: "#FFB725",
    },
    {
      type: "recurring",
      color: "#FFA725",
    },
  ];

  const createDateTime = (date, time) => {
    // Extract hours and minutes from startTime
    const [hours, minutes] = time.split(":").map(Number);

    // Create a new Date object for dateTime
    const dateTime = new Date(convertTimeStamptoDate(date));
    dateTime.setHours(hours, minutes, 0, 0); // Set hours and minutes

    return dateTime;
  };

  const data = newBookingArray.map((item) => {
    return {
      id: item.id,
      Description: "Booked",
      isReadOnly: true,
      Subject: item.createdName,
      StartTime: createDateTime(item.date, item.startTime),
      EndTime: createDateTime(item.date, item.endTime),
      IsAllDay: false,
      CategoryColor: categoryColors.find(
        (color) => color.type === item.bookingType
      ).color,
    };
  });

  const onEventRendered = (args) => {
    const categoryColor = args.data.CategoryColor;
    args.element.style.backgroundColor = categoryColor;
  };

  const onPopupOpen = (args) => {
    if (
      (args.target &&
        !args.target.classList.contains("e-appointment") &&
        args.type === "QuickInfo") ||
      args.type === "Editor"
    ) {
      args.cancel = true;
    }

    // args.cancel = true;
  };

  const onCellClick = (args) => {
    args.cancel = true;
  };

  const onCellDoubleClick = (args) => {
    args.cancel = true;
  };

  return (
    <div>
      <div className="flex items-center gap-2 bg-white rounded-lg p-2 justify-normal">
        <div className="text-gray-600 font-bold text-sm ">
          Date Selected: {date.toLocaleDateString()}
        </div>
        <button
          className="underline text-xs"
          onClick={() => setDateSelected(null)}
        >
          Reset
        </button>
      </div>

      <div>
        <div>
          <div className="mt-4 flex items-center justify-center">
            <ScheduleComponent
              eventSettings={{ dataSource: data }}
              eventRendered={onEventRendered}
              popupOpen={onPopupOpen}
              
              //   width={800}
              height={500}
              currentView="WorkWeek"
              timeScale={{ interval: 60, slotCount: 1 }}
              rowAutoHeight={true}
              readOnly={true}
              cellClick={onCellClick}
              cellDoubleClick={onCellDoubleClick}
              selectedDate={date}

              //read only
            >
              <ViewsDirective>
                <ViewDirective option="Day" />
                <ViewDirective option="Week" />
                <ViewDirective option="WorkWeek" />
                <ViewDirective option="Month" />
              </ViewsDirective>
              <Inject services={[Day, Week, Month, WorkWeek]} />
            </ScheduleComponent>
          </div>
        </div>
      </div>
    </div>
  );
}
