import React from "react";
import { FaCalendarAlt, FaClock, FaUser, FaInfoCircle } from "react-icons/fa";

export default function RequestCard({ data }) {
  const statusColors = {
    pending: "bg-yellow-100 text-yellow-800",
    approved: "bg-green-100 text-green-800",
    rejected: "bg-red-100 text-red-800",
    returned: "bg-gray-100 text-gray-800",
  };

  const leaveTypeColors = {
    "Vacation Leave": "bg-indigo-100 text-indigo-800",
    "Sick Leave": "bg-orange-100 text-orange-800",
    "Emergency Leave": "bg-red-100 text-red-800",
    "Maternity Leave": "bg-pink-100 text-pink-800",
    "Paternity Leave": "bg-purple-100 text-purple-800",
    "Bereavement Leave": "bg-gray-100 text-gray-800",
    "Unpaid Leave": "bg-amber-100 text-amber-800",
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  return (
    <div className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 p-4">
      <div className="flex flex-col md:flex-row justify-between gap-4">
        <div className="flex-1 space-y-4">
          {/* Header */}
          <div className="flex items-start justify-between">
            <div className="space-y-1">
              <div
                className={`inline-block px-3 py-1 rounded-full text-sm font-medium ${
                  leaveTypeColors[data.leaveType] || "bg-gray-100 text-gray-800"
                }`}
              >
                {data.leaveType}
              </div>
              <div className="flex items-center gap-2 text-gray-600">
                <FaUser className="text-gray-400" />
                <span className="font-medium">{data.name}</span>
              </div>
            </div>
            <div
              className={`px-3 py-1 rounded-full text-sm font-medium ${
                statusColors[data.status.toLowerCase()]
              }`}
            >
              {data.status}
            </div>
          </div>

          {/* Details */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex items-center gap-2 text-gray-600">
              <FaCalendarAlt className="text-gray-400" />
              <div>
                <div className="text-sm font-medium">Duration</div>
                <div>
                  {formatDate(data.startDate)} - {formatDate(data.endDate)}
                </div>
              </div>
            </div>
            <div className="flex items-center gap-2 text-gray-600">
              <FaClock className="text-gray-400" />
              <div>
                <div className="text-sm font-medium">Days Requested</div>
                <div>
                  {data.numberOfDays} day{data.numberOfDays > 1 ? "s" : ""}
                </div>
              </div>
            </div>
          </div>

          {/* Reason */}
          {data.reason && (
            <div className="flex items-start gap-2 text-gray-600">
              <FaInfoCircle className="text-gray-400 mt-1" />
              <div>
                <div className="text-sm font-medium">Reason</div>
                <div className="text-gray-600">{data.reason}</div>
              </div>
            </div>
          )}
        </div>

        {/* Timeline */}
        <div className="flex flex-col items-center text-sm text-gray-500 border-l pl-4">
          <div>
            <div className="font-medium">Submitted</div>
            <div>{formatDate(data.createdAt)}</div>
          </div>
          {data.updatedAt && (
            <div className="mt-2">
              <div className="font-medium">Last Updated</div>
              <div>{formatDate(data.updatedAt)}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
