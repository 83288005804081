import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createNewDocument, getAllDocsSnap } from "../../utils/firebase.utils";
import Button from "../../components/Button";
import InputBox from "../../components/InputBox";
import Modal from "./Modal";

// Simple avatar component that shows initials
const EmployeeAvatar = ({ name }) => {
  const getInitials = (name) => {
    if (!name) return "";
    const names = name.split(" ");
    if (names.length === 1) return names[0].charAt(0).toUpperCase();
    return (
      names[0].charAt(0) + names[names.length - 1].charAt(0)
    ).toUpperCase();
  };

  return (
    <div className="w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center text-white text-sm font-medium">
      {getInitials(name)}
    </div>
  );
};

// Custom button component with modern styling
const ModernButton = ({
  label,
  onClick,
  variant = "primary",
  disabled = false,
  type = "button",
}) => {
  const baseStyle =
    "px-4 py-2 rounded-lg font-medium text-sm transition-all duration-200 ease-in-out flex items-center gap-2";
  const variants = {
    primary: "bg-blue-600 text-white hover:bg-blue-700 disabled:bg-blue-300",
    secondary:
      "bg-gray-100 text-gray-700 hover:bg-gray-200 disabled:bg-gray-50 disabled:text-gray-400",
    danger: "bg-red-600 text-white hover:bg-red-700 disabled:bg-red-300",
  };

  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={`${baseStyle} ${variants[variant]}`}
    >
      {label}
    </button>
  );
};

function Employees() {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [addMode, setAddMode] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({
    key: "lastName",
    direction: "asc",
  });
  const [filterBy, setFilterBy] = useState("all");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployees = async () => {
      await getAllDocsSnap("employees", (data) => {
        setEmployees(data);
        setIsLoading(false);
      });
    };
    fetchEmployees();
  }, []);

  const validateForm = () => {
    const errors = {};
    const requiredFields = [
      "employeeNumber",
      "firstName",
      "lastName",
      "position",
      "department",
      "basicSalary",
    ];

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        errors[field] = "This field is required";
      }
    });

    if (formData.basicSalary && isNaN(formData.basicSalary)) {
      errors.basicSalary = "Must be a valid number";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (formErrors[name]) {
      setFormErrors({ ...formErrors, [name]: null });
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      await createNewDocument("employees", {
        ...formData,
        displayName: `${formData.lastName}, ${formData.firstName} ${
          formData.middleName || ""
        }`,
        status: "pending approval",
      });
      setAddMode(false);
      setFormData({});
    } catch (error) {
      console.error("Error saving employee:", error);
    }
  };

  const handleSort = (key) => {
    setSortConfig({
      key,
      direction:
        sortConfig.key === key && sortConfig.direction === "asc"
          ? "desc"
          : "asc",
    });
  };

  const sortedAndFilteredItems = employees
    .filter((employee) => {
      const searchMatch =
        employee.lastName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        employee.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        employee.middleName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        employee.department?.toLowerCase().includes(searchTerm.toLowerCase());

      const statusMatch =
        filterBy === "all" ||
        employee.status?.toLowerCase() === filterBy.toLowerCase();

      console.log("Status Check:", {
        employeeStatus: employee.status?.toLowerCase(),
        filterBy: filterBy.toLowerCase(),
        isMatch: employee.status?.toLowerCase() === filterBy.toLowerCase(),
      });

      return searchMatch && statusMatch;
    })
    .sort((a, b) => {
      if (sortConfig.direction === "asc") {
        return a[sortConfig.key]?.localeCompare(b[sortConfig.key]);
      }
      return b[sortConfig.key]?.localeCompare(a[sortConfig.key]);
    });

  const currentItems = sortedAndFilteredItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const numberOfPages = Math.ceil(sortedAndFilteredItems.length / itemsPerPage);

  const SortIcon = ({ column }) => (
    <span className="ml-1 text-gray-400">
      {sortConfig.key === column
        ? sortConfig.direction === "asc"
          ? "↑"
          : "↓"
        : "↕"}
    </span>
  );

  // Calculate counts for each status with case-insensitive matching
  const statusCounts = employees.reduce((acc, emp) => {
    const status = emp.status?.toLowerCase() || "unknown";
    acc[status] = (acc[status] || 0) + 1;
    return acc;
  }, {});

  // Filter button component with consistent casing
  const FilterButton = ({ status, label, count }) => (
    <button
      onClick={() => setFilterBy(status.toLowerCase())}
      className={`px-4 py-2 rounded-lg text-sm font-medium transition-all duration-200 flex items-center gap-2 ${
        filterBy.toLowerCase() === status.toLowerCase()
          ? "bg-blue-50 text-blue-700 border-2 border-blue-200"
          : "bg-white text-gray-600 border border-gray-200 hover:bg-gray-50"
      }`}
    >
      {label}
      <span className="bg-gray-100 text-gray-600 px-2 py-0.5 rounded-full text-xs">
        {count}
      </span>
    </button>
  );

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-pulse flex items-center gap-2">
          <div className="h-2 w-2 bg-blue-600 rounded-full animate-bounce"></div>
          <div className="h-2 w-2 bg-blue-600 rounded-full animate-bounce delay-75"></div>
          <div className="h-2 w-2 bg-blue-600 rounded-full animate-bounce delay-150"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="max-w-7xl mx-auto space-y-6">
        {/* Header Section */}
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-2xl font-bold text-gray-800">Employees</h1>
            <p className="text-sm text-gray-500 mt-1">
              Manage your organization's workforce
            </p>
          </div>
          <ModernButton
            label="Add Employee"
            onClick={() => setAddMode(true)}
            variant="primary"
          />
        </div>

        {/* Search and Filter Section */}
        <div className="bg-white rounded-xl shadow-sm p-4 space-y-4">
          {/* Search Bar */}
          <div className="flex items-center justify-between gap-4">
            <div className="flex-1">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search employees by name, department..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
                <svg
                  className="absolute left-3 top-2.5 h-5 w-5 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </div>
            </div>
            <div className="text-sm text-gray-600">
              {sortedAndFilteredItems.length} employees
            </div>
          </div>

          {/* Status Filter Buttons */}
          <div className="flex flex-wrap gap-2">
            <FilterButton
              status="all"
              label="All Status"
              count={employees.length}
            />
            <FilterButton
              status="regular"
              label="Regular"
              count={statusCounts["regular"] || 0}
            />
            <FilterButton
              status="probationary"
              label="Probationary"
              count={statusCounts["probationary"] || 0}
            />
            <FilterButton
              status="resigned"
              label="Resigned"
              count={statusCounts["resigned"] || 0}
            />
            <FilterButton
              status="pending approval"
              label="Pending Approval"
              count={statusCounts["pending approval"] || 0}
            />
          </div>
        </div>

        {/* Table Section */}
        <div className="bg-white rounded-xl shadow-sm overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr className="bg-gray-50">
                  <th
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                    onClick={() => handleSort("employeeNumber")}
                  >
                    ID <SortIcon column="employeeNumber" />
                  </th>
                  <th
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                    onClick={() => handleSort("lastName")}
                  >
                    Employee <SortIcon column="lastName" />
                  </th>
                  <th
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                    onClick={() => handleSort("position")}
                  >
                    Position <SortIcon column="position" />
                  </th>
                  <th
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                    onClick={() => handleSort("department")}
                  >
                    Department <SortIcon column="department" />
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Status
                  </th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {currentItems.map((employee) => (
                  <tr
                    key={employee.id}
                    className="hover:bg-gray-50 transition-colors duration-150 ease-in-out"
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {employee.employeeNumber}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <EmployeeAvatar
                          name={`${employee.firstName} ${employee.lastName}`}
                        />
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            {employee.lastName}, {employee.firstName}
                          </div>
                          <div className="text-sm text-gray-500">
                            {employee.email}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {employee.position}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {employee.department}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          employee.status === "regular"
                            ? "bg-green-100 text-green-800"
                            : employee.status === "resigned"
                            ? "bg-red-100 text-red-800"
                            : employee.status === "probationary"
                            ? "bg-blue-100 text-blue-800"
                            : employee.status === "pending approval"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-gray-100 text-gray-800"
                        }`}
                      >
                        {employee.status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <ModernButton
                        label="View Details"
                        onClick={() => navigate(`/employees/${employee.id}`)}
                        variant="secondary"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination */}
          <div className="px-6 py-4 flex items-center justify-between border-t border-gray-200">
            <div className="flex-1 flex justify-between sm:hidden">
              <ModernButton
                label="Previous"
                onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
                disabled={currentPage === 1}
                variant="secondary"
              />
              <ModernButton
                label="Next"
                onClick={() =>
                  setCurrentPage(Math.min(numberOfPages, currentPage + 1))
                }
                disabled={currentPage === numberOfPages}
                variant="secondary"
              />
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div>
                <p className="text-sm text-gray-700">
                  Showing{" "}
                  <span className="font-medium">
                    {(currentPage - 1) * itemsPerPage + 1}
                  </span>{" "}
                  to{" "}
                  <span className="font-medium">
                    {Math.min(
                      currentPage * itemsPerPage,
                      sortedAndFilteredItems.length
                    )}
                  </span>{" "}
                  of{" "}
                  <span className="font-medium">
                    {sortedAndFilteredItems.length}
                  </span>{" "}
                  results
                </p>
              </div>
              <div className="flex gap-2">
                <ModernButton
                  label="Previous"
                  onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
                  disabled={currentPage === 1}
                  variant="secondary"
                />
                <ModernButton
                  label="Next"
                  onClick={() =>
                    setCurrentPage(Math.min(numberOfPages, currentPage + 1))
                  }
                  disabled={currentPage === numberOfPages}
                  variant="secondary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Add Employee Modal */}
      {addMode && (
        <Modal>
          <div className="w-full max-w-3xl mx-auto">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-bold">Add New Employee</h2>
              <button
                className="text-gray-500 hover:text-gray-700"
                onClick={() => {
                  setAddMode(false);
                  setFormData({});
                  setFormErrors({});
                }}
              >
                ✕
              </button>
            </div>

            <form
              onSubmit={handleSave}
              className="grid grid-cols-1 md:grid-cols-2 gap-4"
            >
              <InputBox
                label="Employee Number"
                name="employeeNumber"
                value={formData.employeeNumber || ""}
                onChange={handleChange}
                error={formErrors.employeeNumber}
              />
              <InputBox
                label="First Name"
                name="firstName"
                value={formData.firstName || ""}
                onChange={handleChange}
                error={formErrors.firstName}
              />
              <InputBox
                label="Middle Name"
                name="middleName"
                value={formData.middleName || ""}
                onChange={handleChange}
              />
              <InputBox
                label="Last Name"
                name="lastName"
                value={formData.lastName || ""}
                onChange={handleChange}
                error={formErrors.lastName}
              />
              <InputBox
                label="Position"
                name="position"
                value={formData.position || ""}
                onChange={handleChange}
                error={formErrors.position}
              />
              <InputBox
                label="Department"
                name="department"
                value={formData.department || ""}
                onChange={handleChange}
                error={formErrors.department}
              />
              <InputBox
                type="number"
                label="Basic Salary"
                name="basicSalary"
                value={formData.basicSalary || ""}
                onChange={handleChange}
                error={formErrors.basicSalary}
              />

              <div className="md:col-span-2 flex justify-end mt-4 gap-2">
                <ModernButton
                  label="Cancel"
                  onClick={() => {
                    setAddMode(false);
                    setFormData({});
                    setFormErrors({});
                  }}
                  variant="secondary"
                />
                <ModernButton
                  label="Save Employee"
                  type="submit"
                  variant="primary"
                />
              </div>
            </form>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Employees;
