import React from "react";
import Button from "../../components/Button";
import { useState, useEffect } from "react";
import { getAllDocsSnap, createNewDocument } from "../../utils/firebase.utils";
import PayrollDetails from "./PayrollDetails";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

// Color scheme constants
const COLORS = {
  leechiuBlue: "#0066CC",
  leechiuOrange: "#FF6B35",
  lightBlue: "#E6F0FF",
  lightOrange: "#FFF1EC",
  gray: "#F3F4F6",
};

// Status badge styles
const STATUS_STYLES = {
  draft: "bg-orange-100 text-orange-700",
  submitted: "bg-blue-100 text-blue-700",
  approved: "bg-green-100 text-green-700",
};

function Payroll() {
  const [editMode, setEditMode] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [payrollRunDate, setPayrollRunDate] = useState(null);
  const [payrollRuns, setPayrollRuns] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState([]);
  const [submittedPayroll, setSubmittedPayroll] = useState([]);
  const [approvedPayroll, setApprovedPayroll] = useState([]);

  // Add state for expanded sections
  const [expandedSections, setExpandedSections] = useState({
    draft: false,
    pending: false,
    approved: false,
  });

  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    const fetchPayrollRuns = async () => {
      const data = getAllDocsSnap("payrollRuns", (data) => {
        const drafts = data.filter((item) => item.status === "draft");
        const submitted = data.filter((item) => item.status === "submitted");
        const approved = data.filter((item) => item.status === "approved");

        // Sort function for dates (newest to oldest)
        const sortByDateDesc = (a, b) => {
          const dateA = new Date(a.date).getTime();
          const dateB = new Date(b.date).getTime();
          return dateB - dateA;
        };

        // Sort all arrays by date in descending order
        drafts.sort(sortByDateDesc);
        submitted.sort(sortByDateDesc);
        approved.sort(sortByDateDesc);

        setPayrollRuns(drafts);
        setSubmittedPayroll(submitted);
        setApprovedPayroll(approved);
      });
    };
    fetchPayrollRuns();
  }, []);

  const handleSss = (basic) => {
    // const limit = 29750;
    // const baseline = 4250;
    // const increment = 22.5;
    // const multiple = 500;
    // const baseSSS = 202.5;
    // const minSSS = 180;
    // const maxSSS = 1350;

    const limit = 34750;
    const baseline = 5250;
    const increment = 25;
    const multiple = 500;
    const baseSSS = 275;
    const minSSS = 250;
    const maxSSS = 1750;

    if (basic === "") return 0;
    if (basic <= 0) return 0;

    if (basic >= limit) {
      return maxSSS / 2;
    } else if (basic < baseline) {
      return minSSS / 2;
    } else {
      const sss =
        baseSSS + Math.floor((basic - baseline) / multiple) * increment;
      return sss / 2;
    }
  };

  const handlePagibig = (basic) => {
    if (basic === "") return 0;
    if (basic <= 0) return 0;

    if (basic <= 1500) {
      return (basic * 0.02) / 2;
    } else if (basic > 5000) {
      return 200 / 2;
    }
  };

  const handlePhilhealth = (basic) => {
    if (basic === "") return 0;
    if (basic <= 0) {
      return 0;
    } else if (basic <= 10000) {
      return 500 / 4;
    } else if (basic > 100000) {
      return 5000 / 4;
    } else return (basic * 0.05) / 4;
  };

  const handleThirteenMonth = (basic) => {
    let thirteenMonth = 0;
    if (basic === "") {
      return 0;
    } else {
      thirteenMonth = basic / 24;
      return thirteenMonth;
    }
  };

  const handleTax = (basic) => {
    // change this formula
    let taxableThirteenMonth = 0;
    let sss = handleSss(basic);
    let philhealth = handlePhilhealth(basic);
    let pagibig = handlePagibig(basic);
    let thirteenMonth = handleThirteenMonth(basic);
    let tax = 0;

    taxableThirteenMonth = Math.max(0, thirteenMonth * 24 - 90000);

    const annual =
      (basic / 2 - sss - philhealth - pagibig) * 24 + taxableThirteenMonth;
    if (annual <= 250000) {
      tax = 0;
    } else if (annual <= 400000) {
      tax = (annual - 250000) * 0.15;
    } else if (annual <= 800000) {
      tax = 22500 + (annual - 400000) * 0.2;
    } else if (annual <= 2000000) {
      tax = 102500 + (annual - 800000) * 0.25;
    } else if (annual <= 8000000) {
      tax = 402500 + (annual - 2000000) * 0.3;
    } else if (annual > 8000000) {
      tax = 2202500 + (annual - 8000000) * 0.35;
    }
    return tax / 24;
  };

  const handleSavePayrollRun = () => {
    if (payrollRunDate === null || payrollRunDate === undefined) {
      return;
    }
    const newPayrollRun = async () => {
      const data = {
        date: payrollRunDate,
        status: "draft",
        payregister: employees,
      };
      try {
        const res = await createNewDocument("payrollRuns", data);
      } catch (error) {}
    };
    newPayrollRun();
    setEditMode(false);
    toast.success("Payroll Run Created");
  };

  const handleSelectDate = (e) => {
    setPayrollRunDate(e.target.value);
  };

  useEffect(() => {
    const fetchEmployees = async () => {
      const data = getAllDocsSnap("employees", (data) => {
        //map the data
        let basicSalary = 0;

        //active employees only
        const activeEmployees = data.filter((emp) => emp.status !== "Resigned");

        const mappedData = activeEmployees.map((item) => {
          if (
            item.basicSalary === undefined ||
            parseFloat(item.basicSalary) === 0
          ) {
            basicSalary = 100000;
            return {
              id: item.id,
              displayName: item.displayName,
              employeeNumber: item.employeeNumber || "N/A",
              lastName: item.lastName,
              firstName: item.firstName,
              basicSalary: 0,
              bankAccount: item.bankAccount || "N/A",
              halfMonth: 0,
              SSS: handleSss(basicSalary).toFixed(2),
              HDMF: handlePagibig(basicSalary).toFixed(2),
              Philhealth: handlePhilhealth(basicSalary).toFixed(2),
              thirteenMonth: handleThirteenMonth(basicSalary).toFixed(2),
              reimbursement: 0,
              withholdingTax: handleTax(0).toFixed(2),
              netPay: parseFloat(
                0 -
                  handleSss(basicSalary) -
                  handlePagibig(basicSalary) -
                  handlePhilhealth(basicSalary) -
                  handleTax(0)
              ).toFixed(2),
              commission: 0,
              incentives: 0,
              lwop: 0,
              salaryAdjustment: 0,
              overtime: 0,
              otherDeductions: 0,
              advances: 0,
              maxicare: 0,
              hdmf2: 0,
              hdmfloan: 0,
              sssloan: 0,
            };
          } else {
            basicSalary = item.basicSalary;
          }
          return {
            id: item.id,
            displayName: item.displayName,
            employeeNumber: item.employeeNumber || "N/A",
            lastName: item.lastName,
            firstName: item.firstName,
            basicSalary: basicSalary,
            bankAccount: item.bankAccount || "N/A",
            halfMonth: parseFloat(basicSalary / 2).toFixed(2),
            SSS: handleSss(basicSalary).toFixed(2),
            HDMF: handlePagibig(basicSalary).toFixed(2),
            Philhealth: handlePhilhealth(basicSalary).toFixed(2),
            thirteenMonth: handleThirteenMonth(basicSalary).toFixed(2),
            reimbursement: 0,
            withholdingTax: handleTax(basicSalary).toFixed(2),
            netPay: parseFloat(
              basicSalary / 2 -
                handleSss(basicSalary) -
                handlePagibig(basicSalary) -
                handlePhilhealth(basicSalary) -
                handleTax(basicSalary)
            ).toFixed(2),
            commission: 0,
            incentives: 0,
            lwop: 0,
            salaryAdjustment: 0,
            overtime: 0,
            otherDeductions: 0,
            advances: 0,
            maxicare: 0,
            hdmf2: 0,
            hdmfloan: 0,
            sssloan: 0,
          };
        });
        setEmployees(mappedData);
      });
    };
    fetchEmployees();
  }, []);

  // Format date to be more readable
  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  // Toggle expanded state for a section
  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  // Get limited items for each section
  const getLimitedItems = (items, section) => {
    return expandedSections[section] ? items : items.slice(0, 5);
  };

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="max-w-7xl mx-auto">
        {/* Header Section with Action Button */}
        <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
          <div className="flex justify-between items-center">
            <div>
              <h1 className="header">
                Payroll Management
              </h1>
              <p className="text-gray-600 text-sm">
                Make sure to update salary information in 201 prior to running
                payroll
              </p>
            </div>
            {currentUser.roles.includes("maker") && (
              <Button
                label={editMode ? "Cancel" : "Run Payroll"}
                onClick={() => setEditMode(!editMode)}
                className={`${
                  editMode
                    ? "bg-gray-500 hover:bg-gray-600"
                    : "bg-[#FF6B35] hover:bg-orange-600"
                } text-white px-8 py-3 rounded-lg transition-all duration-200 text-base font-semibold shadow-sm hover:shadow-md`}
              />
            )}
          </div>
        </div>

        {/* Payroll Date Selection */}
        {editMode && (
          <div className="bg-white rounded-xl shadow-sm p-6 mb-8">
            <div className="flex items-center gap-4">
              <label className="text-gray-700 font-medium">
                Select Payroll Date
              </label>
              <input
                type="date"
                className="px-4 py-2 rounded-lg border border-gray-200 focus:outline-none focus:ring-2 focus:ring-[#0066CC]"
                name="payrollRunDate"
                onChange={handleSelectDate}
                required
              />
              <Button
                label="Process Payroll"
                onClick={handleSavePayrollRun}
                className="bg-[#0066CC] hover:bg-blue-700 text-white px-6 py-2 rounded-lg transition-all duration-200 font-semibold shadow-sm hover:shadow-md"
              />
            </div>
          </div>
        )}

        {/* Payroll Sections Grid Layout */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
          {/* Draft Payroll Section */}
          {currentUser.roles.includes("maker") && (
            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
              <div className="p-4 border-b border-gray-100 bg-orange-50">
                <div className="flex justify-between items-center">
                  <h2 className="text-lg font-semibold text-[#FF6B35]">
                    Draft Payroll Runs
                  </h2>
                  <span className="text-sm text-orange-600">
                    {payrollRuns.length} total
                  </span>
                </div>
              </div>
              <div className="divide-y divide-gray-100">
                {getLimitedItems(payrollRuns, "draft").map((item) => (
                  <Link
                    to={`/draftpayroll/${item.id}`}
                    key={item.id}
                    className="block hover:bg-orange-50 transition-all duration-200"
                  >
                    <div className="p-4">
                      <div className="flex items-center justify-between mb-1">
                        <span className="text-sm font-medium text-gray-900">
                          {formatDate(item.date)}
                        </span>
                        <span className="px-2.5 py-0.5 rounded-full text-xs font-medium bg-orange-100 text-orange-700">
                          Draft
                        </span>
                      </div>
                      <div className="text-sm text-gray-500">
                        Click to view details
                      </div>
                    </div>
                  </Link>
                ))}
                {payrollRuns.length === 0 ? (
                  <div className="p-4 text-sm text-gray-500 text-center">
                    No draft payroll runs
                  </div>
                ) : (
                  payrollRuns.length > 5 && (
                    <button
                      onClick={() => toggleSection("draft")}
                      className="w-full p-3 text-sm text-orange-600 hover:bg-orange-50 transition-all duration-200 font-medium"
                    >
                      {expandedSections.draft
                        ? "Show Less"
                        : `Show More (${payrollRuns.length - 5} more)`}
                    </button>
                  )
                )}
              </div>
            </div>
          )}

          {/* Pending Review Section */}
          {(currentUser.roles.includes("approver") ||
            currentUser.roles.includes("maker")) && (
            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
              <div className="p-4 border-b border-gray-100 bg-blue-50">
                <div className="flex justify-between items-center">
                  <h2 className="text-lg font-semibold text-[#0066CC]">
                    Pending Review
                  </h2>
                  <span className="text-sm text-blue-600">
                    {submittedPayroll.length} total
                  </span>
                </div>
              </div>
              <div className="divide-y divide-gray-100">
                {getLimitedItems(submittedPayroll, "pending").map((item) => (
                  <Link
                    to={`/payrollviewmode/${item.id}`}
                    key={item.id}
                    className="block hover:bg-blue-50 transition-all duration-200"
                  >
                    <div className="p-4">
                      <div className="flex items-center justify-between mb-1">
                        <span className="text-sm font-medium text-gray-900">
                          {formatDate(item.date)}
                        </span>
                        <span className="px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-700">
                          Pending
                        </span>
                      </div>
                      <div className="text-sm text-gray-500">
                        Awaiting approval
                      </div>
                    </div>
                  </Link>
                ))}
                {submittedPayroll.length === 0 ? (
                  <div className="p-4 text-sm text-gray-500 text-center">
                    No pending payroll runs
                  </div>
                ) : (
                  submittedPayroll.length > 5 && (
                    <button
                      onClick={() => toggleSection("pending")}
                      className="w-full p-3 text-sm text-blue-600 hover:bg-blue-50 transition-all duration-200 font-medium"
                    >
                      {expandedSections.pending
                        ? "Show Less"
                        : `Show More (${submittedPayroll.length - 5} more)`}
                    </button>
                  )
                )}
              </div>
            </div>
          )}

          {/* Approved Section */}
          {(currentUser.roles.includes("approver") ||
            currentUser.roles.includes("maker")) && (
            <div className="bg-white rounded-xl shadow-sm overflow-hidden">
              <div className="p-4 border-b border-gray-100 bg-green-50">
                <div className="flex justify-between items-center">
                  <h2 className="text-lg font-semibold text-green-600">
                    Approved Payroll
                  </h2>
                  <span className="text-sm text-green-600">
                    {approvedPayroll.length} total
                  </span>
                </div>
              </div>
              <div className="divide-y divide-gray-100">
                {getLimitedItems(approvedPayroll, "approved").map((item) => (
                  <Link
                    to={`/payrollviewmode/${item.id}`}
                    key={item.id}
                    className="block hover:bg-green-50 transition-all duration-200"
                  >
                    <div className="p-4">
                      <div className="flex items-center justify-between mb-1">
                        <span className="text-sm font-medium text-gray-900">
                          {formatDate(item.date)}
                        </span>
                        <span className="px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-700">
                          Approved
                        </span>
                      </div>
                      <div className="text-sm text-gray-500">
                        Processed and approved
                      </div>
                    </div>
                  </Link>
                ))}
                {approvedPayroll.length === 0 ? (
                  <div className="p-4 text-sm text-gray-500 text-center">
                    No approved payroll runs
                  </div>
                ) : (
                  approvedPayroll.length > 5 && (
                    <button
                      onClick={() => toggleSection("approved")}
                      className="w-full p-3 text-sm text-green-600 hover:bg-green-50 transition-all duration-200 font-medium"
                    >
                      {expandedSections.approved
                        ? "Show Less"
                        : `Show More (${approvedPayroll.length - 5} more)`}
                    </button>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Payroll;
