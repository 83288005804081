import React, { useEffect, useState } from "react";
import NewRequestForm from "./NewRequestForm";
import Modal from "../Employees/Modal";
import { queryAllDocsByFieldSnap } from "../../utils/firebase.utils";
import RequestCard from "./RequestCard";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import RequestFormv2 from "./RequestFormv2";

export default function UserRequest() {
  const [addMode, setAddMode] = useState(false);
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentUser = useSelector((state) => state.userObject);

  useEffect(() => {
    setLoading(true);
    const getRequests = async () => {
      const requests = await queryAllDocsByFieldSnap(
        "requests",
        "requestedByEmail",
        currentUser.email,
        (data) => {
          //arrange data by dateREquested
          data.sort((a, b) => {
            return new Date(b.dateRequested) - new Date(a.dateRequested);
          });
          setRequests(data);
          setLoading(false);
        }
      );
    };

    getRequests();
  }, []);

  const handleAddMode = () => {
    setAddMode(!addMode);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-white to-gray-50">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-leechiuBlue border-t-transparent"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-white to-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        {/* Header Section */}
        <div className="mb-12">
          <h1 className="header">
            HR Request
          </h1>
          <p className="text-gray-600">
            Manage and track your HR requests in one place
          </p>
        </div>

        {/* Action Button */}
        <div className="w-full flex justify-start mb-8">
          <button
            className="bg-leechiuBlue hover:bg-leechiuBlue/90 text-white font-semibold px-8 py-3 rounded-xl 
            shadow-lg hover:shadow-xl transition-all duration-300 flex items-center space-x-2 w-full sm:w-auto"
            onClick={handleAddMode}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                clipRule="evenodd"
              />
            </svg>
            <span>Create New Request</span>
          </button>
        </div>

        {/* Requests Grid */}
        <div className="grid gap-4 md:gap-6">
          {requests.map((request, index) => (
            <div
              key={index}
              className="transform transition-all duration-300 hover:scale-[1.02]"
            >
              <Link to={`/request/${request.id}`} className="block">
                <div className="bg-white rounded-xl shadow-sm hover:shadow-md p-6 border border-gray-100">
                  <RequestCard request={request} />
                </div>
              </Link>
            </div>
          ))}
        </div>

        {/* Empty State */}
        {requests.length === 0 && (
          <div className="text-center mt-12 p-12 bg-white rounded-2xl shadow-sm border border-gray-100">
            <div className="mb-6">
              <div className="w-20 h-20 mx-auto bg-leechiuBlue/10 rounded-full flex items-center justify-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-10 w-10 text-leechiuBlue"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                  />
                </svg>
              </div>
            </div>
            <h3 className="text-xl md:text-2xl font-semibold text-gray-800 mb-2">
              No Requests Yet
            </h3>
            <p className="text-gray-500 text-sm md:text-base max-w-md mx-auto">
              Get started by creating your first HR request. We're here to help!
            </p>
            <button
              onClick={handleAddMode}
              className="mt-6 inline-flex items-center text-leechiuBlue hover:text-leechiuBlue/90 font-medium"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  clipRule="evenodd"
                />
              </svg>
              Create Your First Request
            </button>
          </div>
        )}

        {/* Modal */}
        {addMode && (
          <Modal>
            <NewRequestForm addMode={addMode} handleAddMode={handleAddMode} />
          </Modal>
        )}
      </div>
    </div>
  );
}
