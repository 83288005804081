import React from "react";
import { convertTimeStamptoDate } from "../../../utils/PipolUtils";

export default function ReservationCard({ data }) {
  return (
    <div className="flex items-center gap-4">
      <div className="w-48">{data.roomName}</div>
      <div className="w-48">{convertTimeStamptoDate(data.createdAt)}</div>
      <div className="w-48">{convertTimeStamptoDate(data.date)}</div>
      <div className="w-48">{data.startTime}</div>
      <div className="w-48">{data.endTime}</div>
      <div className="w-48">{data.status}</div>
    </div>
  );
}
