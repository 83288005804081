import React, { useState } from "react";
import LeaveRequestPage from "./LeaveRequestPage";
import LeaveHistory from "./LeaveHistory";

export default function LeavesMainPage() {
  const [activeLink, setActiveLink] = useState("Leave Request");
  const links = ["Leave Request", "Leave History"];

  return (
    <div className="pt-8 text-gray-600">
      <div className="header mb-6">Leave Management</div>

      <div className="flex space-x-4 mb-8">
        {links.map((link, index) => (
          <button
            key={index}
            className={`px-4 py-2 rounded-lg font-medium transition-all duration-200 ${
              activeLink === link
                ? "bg-blue-100 text-blue-600"
                : "text-gray-600 hover:bg-gray-100"
            }`}
            onClick={() => setActiveLink(link)}
          >
            {link}
          </button>
        ))}
      </div>

      {activeLink === "Leave Request" ? <LeaveRequestPage /> : <LeaveHistory />}
    </div>
  );
}
