import React from "react";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import NavBar from "./components/NavBar";
import { Outlet } from "react-router-dom";
import Logo from "./assets/leechiuLogo.png";
import SearchBox from "./components/SearchBox";
import Sidebar from "./components/Sidebar";
import LogOut from "./components/LogOut";

function Main() {
  const [isOpen, setIsOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(false);

  //create a window size event listener
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsOpen(false);
      setIsMobile(true);
    }
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsOpen(false);
        setIsMobile(true);
      } else {
        setIsOpen(true);
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="flex text-xs bg-gray-100 min-h-screen">
      {isOpen && (
        <div className="w-[250px] min-h-screen fixed top-0 left-0 bg-gray-100 z-10">
          {isMobile && (
            <div>
              <button
                className="absolute top-2 right-2 text-neutral-900"
                onClick={() => setIsOpen(!isOpen)}
              >
                <CloseIcon />
              </button>
            </div>
          )}
          <div className="flex items-center justify-center">
            <img
              src={Logo}
              alt=""
              className="h-20 mt-8 rounded-2xl px-2 py-1"
            />
          </div>
          <div className="mt-4">
            <SearchBox />
          </div>
          <div className="text-neutral-900 text-sm min-h-screen bg-white">
            <Sidebar />
          </div>
          <div>
            <div className="flex items-center justify-center mt-8">
              <LogOut />
            </div>
          </div>
        </div>
      )}

      {/* right side */}
      <div
        className={
          isMobile
            ? "w-full transition-all duration-500"
            : "w-full ml-[250px] relative"
        }
      >
        {isMobile && (
          <div>
            {!isOpen && (
              <button
                className="absolute top-4 left-4"
                onClick={() => setIsOpen(!isOpen)}
              >
                <MenuIcon />
              </button>
            )}
          </div>
        )}
        <div className={isMobile ? "pl-12 bg-gray-200" : ""}>
          <NavBar isMobile={isMobile} />
        </div>
        <div className="p-4 bg-gray-100 max-w-7xl">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Main;
