import React from "react";

import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import AdminDashboard from "../AdminDashboard";
import { useSearchParams } from "react-router-dom";
import Modal from "../Employees/Modal";
import Clockin from "./Clockin";
import { useNavigate } from "react-router-dom";
import QuickLinks from "../../components/QuickLinks";
import UserAnnouncements from "../Announcements/UserAnnouncements";

import { FaUserClock } from "react-icons/fa";
import { FcMoneyTransfer } from "react-icons/fc";
import { GrDocumentTransfer } from "react-icons/gr";
import { FaPersonSwimming } from "react-icons/fa6";
import { FaBookOpenReader } from "react-icons/fa6";
import { GoGraph } from "react-icons/go";
import { MdMeetingRoom } from "react-icons/md";

function Dashboard() {
  const currentUser = useSelector((state) => state.userObject);
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const splash = searchParams.get("splash");

  if (currentUser.roles.length < 1) {
    return (
      <div className="h-full flex items-center justify-center font-semibold text-lg">
        Congratulations, your account has been created. However, please wait for
        the activation of your access rights.
      </div>
    );
  }

  if (currentUser.roles.includes("admin")) {
    return (
      <div>
        <AdminDashboard />
      </div>
    );
  }

  const handleSplash = () => {
    setSearchParams({ splash: "true" });
  };

  if (currentUser.roles.includes("user")) {
    return (
      <div className="flex items-start gap-4 flex-col lg:flex-row">
        <div className="min-w-96">
          <div className="text-lg font-bold text-blue-900">
            Welcome to your Dashboard
          </div>
          <QuickLinks handleSplash={handleSplash} />

          {currentUser.roles.includes("department approver") && (
            <div className="flex flex-col items-center justify-start border bg-gray-200 rounded-lg px-8 py-2 mt-8">
              <div className="header mt-8">Links for Department Approvers</div>
              <div className="flex items-center gap-4 py-4">
                <NavLink className="underline" to="/approverInbox">
                  Leave Requests for Approval
                </NavLink>
              </div>
            </div>
          )}
        </div>

        <div className="w-full flex items-start justify-normal">
          <div className="max-w-[380px] max-sm:m-auto w-full mt-8">
            <div className="header text-center mb-4">Announcements</div>
            <UserAnnouncements />
          </div>
        </div>

        {splash === "true" && (
          <Modal>
            <Clockin setSearchParams={setSearchParams} />
          </Modal>
        )}
      </div>
    );
  }

  return <div>Dashboard</div>;
}

export default Dashboard;
