import React from "react";
import { useNavigate } from "react-router-dom";
import { FaUserClock } from "react-icons/fa";
import { FcMoneyTransfer } from "react-icons/fc";
import { GrDocumentTransfer } from "react-icons/gr";
import { FaPersonSwimming } from "react-icons/fa6";
import { FaBookOpenReader } from "react-icons/fa6";
import { GoGraph } from "react-icons/go";
import { MdMeetingRoom } from "react-icons/md";

function QuickLinks({ handleSplash }) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center justify-start border bg-gray-200 rounded-lg px-2 lg:px-8 py-2 mt-8">
      <div className="header">Quick Links</div>
      <div className="flex items-center gap-4 py-4 flex-wrap justify-center">
        <div className="">
          <button
            className="flex flex-col items-center gap-2 font-bold p-1 rounded-xl bg-white h-16 w-16 text-xs shadow-sm"
            onClick={handleSplash}
          >
            <FaUserClock className="text-4xl text-blue-800" />
            Clock In / Out
          </button>
        </div>
        <div className="">
          <button
            className="flex flex-col items-center gap-2 font-bold p-1 rounded-xl bg-white h-16 w-16 text-xs shadow-sm"
            onClick={() => navigate("/compensation")}
          >
            <GrDocumentTransfer className="text-4xl text-blue-800" />
            Payslips
          </button>
        </div>
        <div className="">
          <button
            className="flex flex-col items-center gap-2 font-bold p-1 rounded-xl bg-white h-16 w-16 text-xs shadow-sm"
            onClick={() => navigate("/requests")}
          >
            <FcMoneyTransfer className="text-4xl text-blue-800" />
            HR Request
          </button>
        </div>
        <div className="">
          <button
            className="flex flex-col items-center gap-2 font-bold p-1 rounded-xl bg-white h-16 w-16 text-xs shadow-sm"
            onClick={() => navigate("/leaves")}
          >
            <FaPersonSwimming className="text-4xl text-blue-800" />
            Leave Request
          </button>
        </div>
        <div className="">
          <button
            className="flex flex-col items-center gap-2 font-bold p-1 rounded-xl bg-white h-16 w-16 text-xs shadow-sm"
            onClick={() => navigate("/training")}
          >
            <FaBookOpenReader className="text-4xl text-blue-800" />
            Training
          </button>
        </div>
        <div className="">
          <button
            className="flex flex-col items-center gap-2 font-bold p-1 rounded-xl bg-white h-16 w-16 text-xs shadow-sm"
            onClick={() => navigate("/appraisal")}
          >
            <GoGraph className="text-4xl text-blue-800" />
            Appraisal
          </button>
        </div>
        <div className="">
          <button
            className="flex flex-col items-center gap-2 font-bold p-1 rounded-xl bg-white h-16 w-16 text-xs shadow-sm"
            onClick={() => navigate("/meeting-room")}
          >
            <MdMeetingRoom className="text-4xl text-blue-800" />
            Meeting Room
          </button>
        </div>
      </div>
    </div>
  );
}

export default QuickLinks;
