import React, { useEffect, useState } from "react";
import Modal from "../Employees/Modal";
import NewLeaveForm from "./NewLeaveForm";
import {
  getAllDocsSnap,
  queryAllDocsByFieldSnap,
} from "../../utils/firebase.utils";
import RequestCard from "./RequestCard";
import PageModal from "../../components/PageModal";
import RequestDetails from "./RequestDetails";
import { useSelector } from "react-redux";
import { FaCalendarAlt, FaListUl } from "react-icons/fa";
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  Day,
  Week,
  Month,
  Inject,
} from "@syncfusion/ej2-react-schedule";

export default function LeaveRequestPage() {
  const [addMode, setAddMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [requests, setRequests] = useState([]);
  const [viewMode, setViewMode] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [viewType, setViewType] = useState("list"); // list or calendar
  const [filterStatus, setFilterStatus] = useState("all");
  const currentUser = useSelector((state) => state.userObject);
  const [leaveStats, setLeaveStats] = useState({
    total: 0,
    pending: 0,
    approved: 0,
    rejected: 0,
  });

  useEffect(() => {
    const fetchRequests = async () => {
      await queryAllDocsByFieldSnap(
        "leaveRequests",
        "requestedBy",
        currentUser.email,
        (data) => {
          // Arrange data by createdAt
          data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setRequests(data || []);

          // Calculate stats
          const stats = {
            total: data.length,
            pending: data.filter((r) => r.status === "Pending").length,
            approved: data.filter((r) => r.status === "Approved").length,
            rejected: data.filter((r) => r.status === "Rejected").length,
          };
          setLeaveStats(stats);
          setIsLoading(false);
        }
      );
    };
    fetchRequests();
  }, []);

  const filteredRequests = requests.filter((request) => {
    if (filterStatus === "all") return true;
    return request.status.toLowerCase() === filterStatus.toLowerCase();
  });

  const calendarData = requests
    .filter((request) => request.status === "Approved")
    .map((request) => ({
      Id: request.id,
      Subject: `${request.leaveType} - ${request.name}`,
      StartTime: new Date(request.startDate),
      EndTime: new Date(request.endDate),
      IsAllDay: true,
      CategoryColor: request.status === "Approved" ? "#4CAF50" : "#FFA000",
    }));

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="pt-8 text-gray-600">
      {/* Stats Dashboard */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
        <div className="bg-white rounded-lg shadow p-4">
          <div className="text-sm text-gray-500">Total Requests</div>
          <div className="text-2xl font-bold text-gray-700">
            {leaveStats.total}
          </div>
        </div>
        <div className="bg-white rounded-lg shadow p-4">
          <div className="text-sm text-gray-500">Pending</div>
          <div className="text-2xl font-bold text-yellow-600">
            {leaveStats.pending}
          </div>
        </div>
        <div className="bg-white rounded-lg shadow p-4">
          <div className="text-sm text-gray-500">Approved</div>
          <div className="text-2xl font-bold text-green-600">
            {leaveStats.approved}
          </div>
        </div>
        <div className="bg-white rounded-lg shadow p-4">
          <div className="text-sm text-gray-500">Rejected</div>
          <div className="text-2xl font-bold text-red-600">
            {leaveStats.rejected}
          </div>
        </div>
      </div>

      {/* Action Bar */}
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-6">
        <div className="flex items-center gap-4">
          <button
            className="blue-button flex items-center gap-2"
            onClick={() => setAddMode(true)}
          >
            <span>Create Leave Request</span>
          </button>
          <div className="flex items-center gap-2 bg-white rounded-lg shadow px-4 py-2">
            <button
              className={`p-2 rounded-lg ${
                viewType === "list"
                  ? "bg-blue-100 text-blue-600"
                  : "text-gray-600"
              }`}
              onClick={() => setViewType("list")}
            >
              <FaListUl />
            </button>
            <button
              className={`p-2 rounded-lg ${
                viewType === "calendar"
                  ? "bg-blue-100 text-blue-600"
                  : "text-gray-600"
              }`}
              onClick={() => setViewType("calendar")}
            >
              <FaCalendarAlt />
            </button>
          </div>
        </div>

        <div className="flex items-center gap-2">
          <select
            className="form-select rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">All Status</option>
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>
      </div>

      {/* Content */}
      {viewType === "list" ? (
        <div className="grid grid-cols-1 gap-4">
          {filteredRequests.map((request, index) => (
            <div
              key={index}
              onClick={() => {
                setSelectedRequest(request);
                setViewMode(true);
              }}
              className="transform transition-all duration-200 hover:scale-[1.01]"
            >
              <RequestCard data={request} />
            </div>
          ))}
          {filteredRequests.length === 0 && (
            <div className="text-center py-8 text-gray-500">
              No leave requests found
            </div>
          )}
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow p-4">
          <ScheduleComponent
            height="550px"
            eventSettings={{ dataSource: calendarData }}
            readonly={true}
          >
            <ViewsDirective>
              <ViewDirective option="Month" />
              <ViewDirective option="Week" />
              <ViewDirective option="Day" />
            </ViewsDirective>
            <Inject services={[Day, Week, Month]} />
          </ScheduleComponent>
        </div>
      )}

      {addMode && (
        <Modal>
          <NewLeaveForm setAddMode={setAddMode} />
        </Modal>
      )}

      {viewMode && (
        <PageModal>
          <RequestDetails data={selectedRequest} setViewMode={setViewMode} />
        </PageModal>
      )}
    </div>
  );
}
