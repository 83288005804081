import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAllDocs, getAllDocsSnap } from "../../utils/firebase.utils";
import { totalDeductions, totalGrossPay } from "../../utils/PayrollUtils";

// Subcomponents
const PayrollDateSelector = ({ selectedDate, onDateChange }) => (
  <div className="mb-6">
    <label className="block text-sm font-medium text-gray-700 mb-2">
      Select Payroll Date
    </label>
    <input
      type="date"
      onChange={(e) => onDateChange(e.target.value)}
      value={selectedDate}
      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent"
    />
  </div>
);

const EmployeeSelector = ({ employees, selectedId, onEmployeeChange }) => (
  <div className="mb-6">
    <label className="block text-sm font-medium text-gray-700 mb-2">
      Select Employee
    </label>
    <select
      className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-orange-500 focus:border-transparent"
      value={selectedId}
      onChange={(e) => onEmployeeChange(e.target.value)}
    >
      {employees.map((employee) => (
        <option key={employee.id} value={employee.id}>
          {employee.lastName} {employee.firstName}
        </option>
      ))}
    </select>
  </div>
);

const EmployeeInfo = ({ employee, selectedDate }) => (
  <div className="bg-white p-6 rounded-lg shadow-sm mb-6">
    <h3 className="text-lg font-semibold text-gray-900 mb-4">
      Employee Details
    </h3>
    <div className="grid grid-cols-2 gap-4 text-sm">
      <div>
        <p className="text-gray-600">Employee Name</p>
        <p className="font-medium">{employee.displayName}</p>
      </div>
      <div>
        <p className="text-gray-600">Department</p>
        <p className="font-medium">{employee.department}</p>
      </div>
      <div>
        <p className="text-gray-600">Position</p>
        <p className="font-medium">{employee.position}</p>
      </div>
      <div>
        <p className="text-gray-600">Payroll Date</p>
        <p className="font-medium">{selectedDate}</p>
      </div>
    </div>
  </div>
);

const PayrollTable = ({ title, data }) => (
  <div className="bg-white rounded-lg shadow-sm overflow-hidden">
    <div className="px-6 py-4 border-b border-gray-200">
      <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
    </div>
    <div className="overflow-x-auto">
      <table className="w-full">
        <tbody>
          {Object.entries(data).map(([label, value], index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
            >
              <td className="px-6 py-3 text-sm text-gray-900">{label}</td>
              <td className="px-6 py-3 text-sm text-gray-900 text-right">
                {value}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

function Compensation() {
  const currentUser = useSelector((state) => state.userObject);
  const [payrollRuns, setPayrollRuns] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [employeeId, setEmployeeId] = useState("");
  const [employeeInfo, setEmployeeInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPayrollRuns = async () => {
      await getAllDocsSnap("payrollRuns", (snap) => {
        const approvedRuns = snap.filter((run) => run.status === "approved");
        setPayrollRuns(approvedRuns);
      });
    };
    fetchPayrollRuns();
  }, []);

  useEffect(() => {
    const fetchEmployeeId = async () => {
      setIsLoading(true);
      try {
        const data = await getAllDocs("employees");
        const sortedData = data.sort((a, b) =>
          a.lastName.localeCompare(b.lastName)
        );
        const activeEmployees = sortedData.filter(
          (employee) => employee.status !== "Resigned"
        );

        setEmployees(activeEmployees);

        if (currentUser.roles.includes("reviewer")) {
          setEmployeeId(activeEmployees[0]?.id);
          setEmployeeInfo(activeEmployees[0]);
        } else {
          const user = activeEmployees.find(
            (employee) => employee.email === currentUser.email
          );
          setEmployeeId(user?.id);
          setEmployeeInfo(user);
        }
      } catch (error) {
        console.error("Error fetching employees:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchEmployeeId();
  }, [currentUser]);

  const converter = (data) => {
    return parseFloat(data).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const handleSelectEmployeeId = (id) => {
    setEmployeeId(id);
    const selectedEmployee = employees.find((employee) => employee.id === id);
    setEmployeeInfo(selectedEmployee);
  };

  const payrollRun = payrollRuns.find((run) => run.date === selectedDate);
  const paySlipData = payrollRun?.payregister.find(
    (item) => item.id === employeeId
  );

  const grossPay = totalGrossPay(paySlipData);
  const deductions = totalDeductions(paySlipData);
  const netPay = grossPay - deductions;

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-4 border-orange-500 border-t-transparent"></div>
      </div>
    );
  }

  const grossPayData = {
    "Basic Salary": converter(paySlipData?.basicSalary || 0),
    "Half Month": converter(paySlipData?.halfMonth || 0),
    "13th Month Pay (Non Taxable)": converter(
      paySlipData?.thirteenMonthPay || 0
    ),
    "13th Month Pay (Taxable)": converter(
      paySlipData?.thirteenMonthPayTaxable || 0
    ),
    Commissions: converter(paySlipData?.commission || 0),
    Incentives: converter(paySlipData?.incentives || 0),
    Overtime: converter(paySlipData?.overtime || 0),
    "Salary Adjustment": converter(paySlipData?.salaryAdjustment || 0),
    "Tax Refund": converter(paySlipData?.taxRefund || 0),
    "Leave without pay": converter(paySlipData?.lwop || 0),
    "Reimbursement /Cash Advances": converter(paySlipData?.reimbursement || 0),
    "Leave Conversion": converter(paySlipData?.leaveConversion || 0),
    "Total Gross Pay": `Php ${converter(grossPay)}`,
  };

  const deductionsData = {
    SSS: converter(paySlipData?.SSS || 0),
    Philhealth: converter(paySlipData?.Philhealth || 0),
    PagIbig: converter(paySlipData?.HDMF || 0),
    HMO: converter(paySlipData?.maxicare || 0),
    "PagIbig 2": converter(paySlipData?.hdmf2 || 0),
    "SSS Loan": converter(paySlipData?.sssloan || 0),
    "PagIbig Loan": converter(paySlipData?.hdmfloan || 0),
    Advances: converter(paySlipData?.advances || 0),
    "Other Deductions": converter(paySlipData?.otherDeductions || 0),
    "Withholding Tax": converter(paySlipData?.withholdingTax || 0),
    "Total Deductions": `Php ${converter(deductions)}`,
  };

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <h2 className="header">Payslips</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
        <PayrollDateSelector
          selectedDate={selectedDate}
          onDateChange={setSelectedDate}
        />
        {currentUser.roles.includes("reviewer") && (
          <EmployeeSelector
            employees={employees}
            selectedId={employeeId}
            onEmployeeChange={handleSelectEmployeeId}
          />
        )}
      </div>

      {paySlipData ? (
        <div className="space-y-6">
          <EmployeeInfo employee={employeeInfo} selectedDate={selectedDate} />

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
            <PayrollTable title="Gross Pay" data={grossPayData} />
            <PayrollTable title="Deductions" data={deductionsData} />
          </div>

          <div className="bg-orange-50 p-6 rounded-lg shadow-sm mt-6">
            <h3 className="text-xl font-bold text-orange-600">
              Net Pay: Php {converter(netPay)}
            </h3>
          </div>
        </div>
      ) : (
        <div className="text-center py-12 bg-gray-50 rounded-lg">
          <p className="text-gray-500">
            No payslip data found for the selected date
          </p>
        </div>
      )}
    </div>
  );
}

export default Compensation;
