import React, { useEffect, useState } from "react";
import PageModal from "../../../components/PageModal";
import MeetingRoomSetup from "./MeetingRoomSetup";
import { getAllDocsSnap } from "../../../utils/firebase.utils";
import MeetingRoomCard from "./MeetingRoomCard";
import ReservationPage from "./ReservationPage";
import { useSelector } from "react-redux";
import MyReservations from "./MyReservations";
import ReservationRequests from "./ReservationRequests";

export default function MeetingRooms() {
  const currentUser = useSelector((state) => state.userObject);
  const [addMode, setAddMode] = useState(false);

  const [isProcessing, setIsProcess] = useState(false);
  const [meetingRooms, setMeetingRooms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewMode, setViewMode] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState({});
  const [activeLink, setActiveLink] = useState("Meeting Rooms");
  const [editMode, setEditMode] = useState(false);
  const [selectedRoomForEdit, setSelectedRoomForEdit] = useState(null);

  const links = [
    {
      name: "Meeting Rooms",
      access: "all",
    },
    {
      name: "My Reservations",
      access: "all",
    },
    {
      name: "Reservation Requests",
      access: "admin-user, admin",
    },
  ];

  useEffect(() => {
    const fetchRooms = async () => {
      await getAllDocsSnap("meeting-rooms", (data) => {
        //sort by name
        data.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        setMeetingRooms(data);
        setIsLoading(false);
      });
    };
    fetchRooms();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleAddMode = () => {
    setAddMode(true);
  };

  const handleViewMode = (room) => {
    setViewMode(true);
    setSelectedRoom(room);
  };

  const allowableAccess = "admin, admin-user";

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  return (
    <div>
      <div className="header">Meeting Room Reservation</div>

      <div className="mt-4 links-container">
        {links.map((link, index) => (
          <div
            className={
              activeLink === link.name ? "activeLinkStyle" : "inactiveLinkStyle"
            }
            onClick={() => setActiveLink(link.name)}
          >
            {link.name}
          </div>
        ))}
      </div>

      {activeLink === "Meeting Rooms" && (
        <div className="mt-4">
          {currentUser.roles.some((role) =>
            allowableAccess.includes("admin")
          ) && (
            <div className="flex items-center gap-4">
              <button className="blue-button" onClick={handleAddMode}>
                Setup
              </button>
              <button className="blue-button" onClick={handleEditMode}>
                {editMode ? "Cancel Edit Mode" : "Edit Mode"}
              </button>
            </div>
          )}

          <div className="mt-4 flex items-center justify-center lg:justify-normal gap-4 flex-wrap">
            {meetingRooms.map((room) => (
              <div key={room.id}>
                <div
                  onClick={() => handleViewMode(room)}
                  className="cursor-pointer hover:shadow-md w-fit rounded-xl"
                >
                  <MeetingRoomCard data={room} />
                </div>
                {editMode && (
                  <button
                    className="underline"
                    onClick={() => setSelectedRoomForEdit(room)}
                  >
                    Edit Room
                  </button>
                )}
              </div>
            ))}
          </div>

          <div>
            {viewMode && (
              <PageModal>
                <ReservationPage
                  data={selectedRoom}
                  setViewMode={() => setViewMode()}
                />
              </PageModal>
            )}
          </div>

          {addMode && (
            <PageModal>
              <MeetingRoomSetup setAddMode={setAddMode} />
            </PageModal>
          )}

          {selectedRoomForEdit !== null ? (
            <PageModal>
              <MeetingRoomSetup
                setAddMode={setAddMode}
                data={selectedRoomForEdit}
                setSelectedRoomForEdit={setSelectedRoomForEdit}
              />
            </PageModal>
          ) : null}
        </div>
      )}

      {activeLink === "My Reservations" && <MyReservations />}
      {activeLink === "Reservation Requests" && <ReservationRequests />}
    </div>
  );
}
