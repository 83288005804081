import React, { useState } from "react";
import InputBox from "../../components/InputBox";
import SelectBox from "../../components/SelectBox";
import { addNewDoc } from "../../utils/firebase.utils";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import { FaCalendarAlt, FaUserTie, FaInfoCircle } from "react-icons/fa";

export default function NewLeaveForm({ setAddMode }) {
  const [formData, setFormData] = useState({});
  const [isProcessing, setIsProcessing] = useState(false);
  const [approver, setApprover] = useState("");
  const [leaveType, setLeaveType] = useState("");
  const [errors, setErrors] = useState({});

  const currentUser = useSelector((state) => state.userObject);

  const typesOfLeaves = [
    "Sick Leave",
    "Vacation Leave",
    "Maternity Leave",
    "Paternity Leave",
    "Bereavement Leave",
    "Unpaid Leave",
  ];

  const leavesOptions = typesOfLeaves.map((leave) => ({
    value: leave,
    label: leave,
  }));

  const approverOption = [
    {
      label: currentUser.leader,
      value: currentUser.leader,
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // Clear error when field is edited
    if (errors[name]) {
      setErrors({ ...errors, [name]: null });
    }
  };

  const handleSelectApprover = (selected) => {
    setApprover(selected.value);
    if (errors.approver) {
      setErrors({ ...errors, approver: null });
    }
  };

  const handleSelectLeaveType = (selected) => {
    setLeaveType(selected.value);
    if (errors.leaveType) {
      setErrors({ ...errors, leaveType: null });
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!leaveType) {
      newErrors.leaveType = "Please select a leave type";
    }
    if (!approver) {
      newErrors.approver = "Please select an approver";
    }
    if (!formData.numberOfDays) {
      newErrors.numberOfDays = "Please enter number of days";
    }
    if (!formData.startDate) {
      newErrors.startDate = "Please select start date";
    }
    if (!formData.endDate) {
      newErrors.endDate = "Please select end date";
    }
    if (!formData.reason) {
      newErrors.reason = "Please provide a reason";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSendNotification = async () => {
    const approverName = approver.split("@")[0];
    const approverFirstName = approverName.split(".")[0];

    const docObject = {
      requestor: currentUser.email,
      recipientEmail: approver,
      recipientName: approverFirstName,
      link: "https://myhr.leechiu.app/approverInbox",
      subject: "Leave Request Notification",
      message: `${currentUser.displayName} is requesting for a Leave Request from ${formData.startDate} to ${formData.endDate}. Reason: ${formData.reason}. Please check your myHR for more details.`,
    };

    await fetch("https://hrms-emailerv1.vercel.app/api/notifications", {
      method: "POST",
      body: JSON.stringify(docObject),
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill in all required fields");
      return;
    }

    setIsProcessing(true);

    try {
      const approverName = approver.split("@")[0];
      const approverFirstName = approverName.split(".")[0];
      const approverLastName = approverName.split(".")[1];

      const docObject = {
        ...formData,
        name: currentUser.displayName,
        approver: approver,
        approverName: approverFirstName + " " + approverLastName,
        leaveType: leaveType,
        requestedBy: currentUser.email,
        status: "Pending",
        createdAt: new Date().toLocaleString(),
      };

      await addNewDoc("leaveRequests", docObject);
      await handleSendNotification();

      toast.success("Leave Request Submitted");
      setAddMode(false);
    } catch (error) {
      toast.error("Failed to submit leave request");
      console.error("Error submitting leave request:", error);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="relative bg-white rounded-lg shadow-lg max-w-2xl mx-auto p-6">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-bold text-gray-800">New Leave Request</h2>
        <button
          onClick={() => setAddMode(false)}
          className="text-gray-500 hover:text-gray-700"
        >
          ✕
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* Leave Type Selection */}
          <div className="space-y-2">
            <SelectBox
              name="leaveType"
              label="Leave Type"
              placeholder="Select Leave Type"
              options={leavesOptions}
              handleSelect={handleSelectLeaveType}
              disabled={isProcessing}
              error={errors.leaveType}
            />
          </div>

          {/* Approver Selection */}
          <div className="space-y-2">
            <SelectBox
              name="approver"
              label="Approver"
              placeholder="Select Approver"
              options={approverOption}
              handleSelect={handleSelectApprover}
              disabled={isProcessing}
              error={errors.approver}
            />
          </div>

          {/* Date Range */}
          <div className="space-y-2">
            <InputBox
              name="startDate"
              type="date"
              label="Start Date"
              placeholder="Start Date"
              onChange={handleChange}
              disabled={isProcessing}
              error={errors.startDate}
              min={new Date().toISOString().split("T")[0]}
            />
          </div>

          <div className="space-y-2">
            <InputBox
              name="endDate"
              type="date"
              label="End Date"
              placeholder="End Date"
              onChange={handleChange}
              disabled={isProcessing}
              error={errors.endDate}
              min={formData.startDate || new Date().toISOString().split("T")[0]}
            />
          </div>

          {/* Number of Days */}
          <div className="space-y-2">
            <InputBox
              name="numberOfDays"
              type="number"
              label="Number of Working Days"
              placeholder="Enter number of days"
              onChange={handleChange}
              disabled={isProcessing}
              error={errors.numberOfDays}
              min="0.5"
              step="0.5"
            />
          </div>
        </div>

        {/* Reason */}
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Reason for Leave
          </label>
          <textarea
            name="reason"
            rows="4"
            className={`mt-1 block w-full rounded-md shadow-sm ${
              errors.reason
                ? "border-red-300 focus:border-red-500 focus:ring-red-500"
                : "border-gray-300 focus:border-blue-500 focus:ring-blue-500"
            }`}
            placeholder="Please provide a detailed reason for your leave request"
            onChange={handleChange}
            disabled={isProcessing}
          ></textarea>
          {errors.reason && (
            <p className="mt-1 text-sm text-red-600">{errors.reason}</p>
          )}
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            disabled={isProcessing}
            className={`px-6 py-2 text-white rounded-lg shadow-sm ${
              isProcessing
                ? "bg-gray-400 cursor-not-allowed"
                : "bg-blue-600 hover:bg-blue-700"
            }`}
          >
            {isProcessing ? (
              <div className="flex items-center gap-2">
                <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                <span>Submitting...</span>
              </div>
            ) : (
              "Submit Request"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
