import React from "react";
import {
  getAllDocsSnap,
  setDocData,
  getEmailsOfAllFirebaseUsers,
} from "../../utils/firebase.utils";
import { useState, useEffect } from "react";
import Select from "react-select";
import InputBox from "../../components/InputBox";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";

function UserAccess() {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUserForEdit, setSelectedUserForEdit] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const currentUser = useSelector((state) => state.userObject);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeLink, setActiveLink] = useState("All Users");

  useEffect(() => {
    const getUsers = async () => {
      const data = await getAllDocsSnap("users", (users) => {
        users.sort((a, b) => {
          if (a.displayName < b.displayName) {
            return -1;
          }
          if (a.displayName > b.displayName) {
            return 1;
          }
          return 0;
        });

        setUsers(users);

        const useremails = users.map((user) => {
          return user.email;
        });

        useremails.sort((a, b) => {
          if (a < b) {
            return -1;
          }
          if (a > b) {
            return 1;
          }
          return 0;
        });

        setFilteredUsers(users);
        setIsLoading(false);
      });
      //arrange users by name
    };
    getUsers();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const options = [
    { value: "user", label: "User" },
    { value: "admin-user", label: "admin-user" },
    { value: "agent", label: "Agent" },
    { value: "analyst", label: "Analyst" },
    { value: "admin", label: "Admin" },
    { value: "super-admin", label: "Super Admin" },
    { value: "hr approver", label: "HR Approver" },
    { value: "hr maker", label: "HR Maker" },
    { value: "department approver", label: "Department Approver" },
    { value: "approver", label: "Approver" },
    { value: "finance", label: "Finance" },
    { value: "finance-head", label: "Finance Head" },
    { value: "marketing", label: "Marketing" },
    { value: "maker", label: "Maker" },
    { value: "restricted", label: "Restricted" },
    { value: "reviewer", label: "Reviewer" },
  ];

  const filters = ["No Access", "All Users"];

  const selectUserForEditing = (id) => {
    setSelectedUserForEdit(id);

    if (selectedUserForEdit === id) {
      setSelectedUserForEdit(null);
    }
  };

  const handleSelect = (userid, selectedOption) => {
    let roles = [];

    if (currentUser.roles.includes("admin")) {
      selectedOption.map((option) => {
        roles.push(option.value);
      });
      setDocData("users", userid, { roles: roles });
    } else {
      toast.error("You are not authorized to perform this action");
    }
  };

  const handleSelectDepartment = (userid, selectedOption) => {
    setDocData("users", userid, { department: selectedOption.value });
  };

  const departmentOptions = [
    { value: "Investment Sales", label: "Investment Sales" },
    { value: "Commercial Leasing", label: "Commercial Leasing" },
    { value: "Research", label: "Research" },
    { value: "Residential", label: "Residential" },
    { value: "Hospitality", label: "Hospitality" },
    { value: "Marketing", label: "Marketing" },
    { value: "Finance", label: "Finance" },
    { value: "HR", label: "Human Resource" },
    { value: "Admin", label: "Admin" },
    { value: "Office of the Ceo", label: "Office of the Ceo" },
  ];

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const currentlist = users.filter((user) => {
    if (activeLink === "All Users") {
      return user;
    } else if (activeLink === "No Access") {
      return user.roles.length < 1;
    }
  });

  const newFilteredUsers = currentlist.filter((user) => {
    return user.displayName.toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <div className="p-4 text-xs text-gray-600">
      <div className="links-container mb-4">
        {filters.map((filter) => (
          <div
            className={
              activeLink === filter ? "activeLinkStyle" : "inactiveLinkStyle"
            }
            onClick={() => setActiveLink(filter)}
          >
            {filter}
          </div>
        ))}
      </div>
      <div>
        <div className="mb-4">
          <InputBox
            label="Search User"
            onChange={handleSearch}
            type={"search"}
            placeholder={"Search for user"}
          />
        </div>
        <table className="text-left text-xs">
          <tr className="[&>*]:p-2 [&>*]:bg-gray-200">
            <th>Full Name</th>
            <th>Email</th>
            <th className="w-72">Department</th>
            <th className="w-72">Roles</th>
          </tr>
          {newFilteredUsers.map((user) => (
            <tr className="[&>*]:p-2 [&>*]:text-xs" key={user.id}>
              <td>{user.displayName}</td>
              <td>{user.email}</td>
              <td>
                {selectedUserForEdit === user.id ? (
                  <Select
                    onChange={(selectedOption) =>
                      handleSelectDepartment(user.id, selectedOption)
                    }
                    options={departmentOptions}
                    name="department"
                    value={departmentOptions.filter((item) => {
                      return user.department === item.value;
                    })}
                  />
                ) : (
                  user.department
                )}
              </td>
              <td>
                {selectedUserForEdit === user.id ? (
                  <Select
                    onChange={(selectedOption) =>
                      handleSelect(user.id, selectedOption)
                    }
                    options={options}
                    isMulti
                    name="roles"
                    value={options.filter((item) => {
                      return user.roles?.includes(item.value);
                    })}
                  />
                ) : (
                  <div className="truncate">
                    {user.roles?.map((role) => (
                      <span
                        className="bg-gray-200 p-2 rounded-md mr-2"
                        key={role}
                      >
                        {role}
                      </span>
                    ))}
                  </div>
                )}
              </td>
              <td>
                <button
                  className="blue-button"
                  onClick={() => selectUserForEditing(user.id)}
                >
                  {selectedUserForEdit === user.id ? "Update" : "Edit"}
                </button>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </div>
  );
}

export default UserAccess;
