import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { queryAllDocsByFieldSnap } from "../../utils/firebase.utils";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import { FaCalendarAlt, FaChartBar, FaFilter } from "react-icons/fa";

export default function LeaveHistory() {
  const [isLoading, setIsLoading] = useState(true);
  const [leaveHistory, setLeaveHistory] = useState([]);
  const [yearFilter, setYearFilter] = useState(new Date().getFullYear());
  const [viewMode, setViewMode] = useState("list"); // 'list' or 'analytics'
  const currentUser = useSelector((state) => state.userObject);

  // Analytics state
  const [leaveAnalytics, setLeaveAnalytics] = useState({
    monthlyDistribution: [],
    leaveTypeDistribution: [],
    totalDays: 0,
    averageDuration: 0,
  });

  useEffect(() => {
    fetchLeaveHistory();
  }, [yearFilter]);

  const fetchLeaveHistory = async () => {
    await queryAllDocsByFieldSnap(
      "leaveRequests",
      "requestedBy",
      currentUser.email,
      (data) => {
        // Filter by year
        const filteredData = data.filter((leave) => {
          const leaveYear = new Date(leave.startDate).getFullYear();
          return leaveYear === yearFilter;
        });

        // Sort by date
        filteredData.sort(
          (a, b) => new Date(b.startDate) - new Date(a.startDate)
        );
        setLeaveHistory(filteredData);

        // Calculate analytics
        calculateAnalytics(filteredData);
        setIsLoading(false);
      }
    );
  };

  const calculateAnalytics = (data) => {
    // Monthly distribution
    const monthlyData = new Array(12).fill(0).map((_, index) => ({
      month: new Date(2024, index).toLocaleString("default", {
        month: "short",
      }),
      count: 0,
      days: 0,
    }));

    // Leave type distribution
    const leaveTypes = {};
    let totalDays = 0;
    let totalRequests = data.length;

    data.forEach((leave) => {
      const month = new Date(leave.startDate).getMonth();
      monthlyData[month].count += 1;
      monthlyData[month].days += Number(leave.numberOfDays) || 0;

      // Leave type counting
      leaveTypes[leave.leaveType] = (leaveTypes[leave.leaveType] || 0) + 1;
      totalDays += Number(leave.numberOfDays) || 0;
    });

    const leaveTypeData = Object.entries(leaveTypes).map(([type, count]) => ({
      name: type,
      value: count,
      percentage: ((count / totalRequests) * 100).toFixed(1),
    }));

    setLeaveAnalytics({
      monthlyDistribution: monthlyData,
      leaveTypeDistribution: leaveTypeData,
      totalDays: totalDays,
      averageDuration: totalRequests
        ? (totalDays / totalRequests).toFixed(1)
        : 0,
    });
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const COLORS = [
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#8884D8",
    "#82CA9D",
  ];

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="pt-8 text-gray-600">
      {/* Header Section */}
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-8">
        <h1 className="text-2xl font-bold text-gray-800">Leave History</h1>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2 bg-white rounded-lg shadow px-4 py-2">
            <button
              className={`p-2 rounded-lg ${
                viewMode === "list"
                  ? "bg-blue-100 text-blue-600"
                  : "text-gray-600"
              }`}
              onClick={() => setViewMode("list")}
            >
              <FaCalendarAlt />
            </button>
            <button
              className={`p-2 rounded-lg ${
                viewMode === "analytics"
                  ? "bg-blue-100 text-blue-600"
                  : "text-gray-600"
              }`}
              onClick={() => setViewMode("analytics")}
            >
              <FaChartBar />
            </button>
          </div>
          <select
            className="form-select rounded-lg border-gray-300 focus:border-blue-500 focus:ring-blue-500"
            value={yearFilter}
            onChange={(e) => setYearFilter(Number(e.target.value))}
          >
            {[...Array(5)].map((_, i) => {
              const year = new Date().getFullYear() - i;
              return (
                <option key={year} value={year}>
                  {year}
                </option>
              );
            })}
          </select>
        </div>
      </div>

      {viewMode === "analytics" ? (
        <div className="space-y-8">
          {/* Summary Cards */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="bg-white rounded-lg shadow p-4">
              <div className="text-sm text-gray-500">Total Leave Days</div>
              <div className="text-2xl font-bold text-blue-600">
                {leaveAnalytics.totalDays}
              </div>
            </div>
            <div className="bg-white rounded-lg shadow p-4">
              <div className="text-sm text-gray-500">Total Requests</div>
              <div className="text-2xl font-bold text-blue-600">
                {leaveHistory.length}
              </div>
            </div>
            <div className="bg-white rounded-lg shadow p-4">
              <div className="text-sm text-gray-500">Average Duration</div>
              <div className="text-2xl font-bold text-blue-600">
                {leaveAnalytics.averageDuration} days
              </div>
            </div>
          </div>

          {/* Charts */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Monthly Distribution Chart */}
            <div className="bg-white rounded-lg shadow p-4">
              <h3 className="text-lg font-semibold mb-4">
                Monthly Leave Distribution
              </h3>
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={leaveAnalytics.monthlyDistribution}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="days" fill="#8884d8" name="Days" />
                    <Bar dataKey="count" fill="#82ca9d" name="Requests" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>

            {/* Leave Type Distribution */}
            <div className="bg-white rounded-lg shadow p-4">
              <h3 className="text-lg font-semibold mb-4">
                Leave Type Distribution
              </h3>
              <div className="h-[300px]">
                <ResponsiveContainer width="100%" height="100%">
                  <PieChart>
                    <Pie
                      data={leaveAnalytics.leaveTypeDistribution}
                      cx="50%"
                      cy="50%"
                      labelLine={false}
                      label={({ name, percentage }) =>
                        `${name}: ${percentage}%`
                      }
                      outerRadius={80}
                      fill="#8884d8"
                      dataKey="value"
                    >
                      {leaveAnalytics.leaveTypeDistribution.map(
                        (entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        )
                      )}
                    </Pie>
                    <Tooltip />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      ) : (
        /* List View */
        <div className="space-y-4">
          {leaveHistory.map((leave, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow duration-200 p-4"
            >
              <div className="flex flex-col md:flex-row justify-between gap-4">
                <div className="space-y-2">
                  <div className="flex items-center gap-2">
                    <span
                      className={`px-3 py-1 rounded-full text-sm font-medium ${
                        leave.status.toLowerCase() === "approved"
                          ? "bg-green-100 text-green-800"
                          : leave.status.toLowerCase() === "rejected"
                          ? "bg-red-100 text-red-800"
                          : "bg-yellow-100 text-yellow-800"
                      }`}
                    >
                      {leave.status}
                    </span>
                    <span className="text-gray-500">•</span>
                    <span className="text-sm text-gray-600">
                      {leave.leaveType}
                    </span>
                  </div>
                  <div className="text-sm text-gray-600">
                    <span className="font-medium">Duration:</span>{" "}
                    {formatDate(leave.startDate)} - {formatDate(leave.endDate)}
                    <span className="ml-2">({leave.numberOfDays} days)</span>
                  </div>
                  {leave.reason && (
                    <div className="text-sm text-gray-600">
                      <span className="font-medium">Reason:</span>{" "}
                      {leave.reason}
                    </div>
                  )}
                </div>
                <div className="text-sm text-gray-500">
                  <div>Submitted on {formatDate(leave.createdAt)}</div>
                  {leave.updatedAt && (
                    <div>Updated on {formatDate(leave.updatedAt)}</div>
                  )}
                </div>
              </div>
            </div>
          ))}

          {leaveHistory.length === 0 && (
            <div className="text-center py-8 text-gray-500">
              No leave history found for {yearFilter}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
