import React, { useEffect, useState } from "react";
import InputBox from "../../../components/InputBox";
import PhotosUploader from "./PhotoUploader";
import { handleUploadMultiple } from "../../../utils/PipolUtils";
import { addNewDoc, setDocData } from "../../../utils/firebase.utils";
import toast from "react-hot-toast";

export default function MeetingRoomSetup({
  setAddMode,
  data,
  setSelectedRoomForEdit,
}) {
  const [formData, setFormData] = useState(data || {});
  const [isProcessing, setIsProcessing] = useState(false);
  const blankObject = {
    name: "",
    value: "",
  };
  const [features, setFeatures] = useState(data.features || [blankObject]);
  const [primaryPhoto, setPrimaryPhoto] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleUpload = async (e) => {
    await handleUploadMultiple(
      e,
      formData,
      setFormData,
      "meeting-rooms",
      "photos"
    );
  };

  const handleNewPhotos = (newPhotos) => {
    setFormData({ ...formData, photos: newPhotos });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const docObject = {
      ...formData,
      features,
      primaryPhoto,
    };

    if (data) {
      await setDocData("meeting-rooms", data.id, docObject);
      toast.success("Meeting Room updated successfully");
      setIsProcessing(false);
      setSelectedRoomForEdit(null);
      return;
    }

    await addNewDoc("meeting-rooms", docObject);
    toast.success("Meeting Room added successfully");
    setIsProcessing(false);
    setAddMode(false);
  };

  return (
    <div className="page relative">
      <button
        className="close-button"
        onClick={() => {
          data ? setSelectedRoomForEdit(null) : setAddMode(false);
        }}
      >
        Close
      </button>
      <div className="header">Meeting Room Setup</div>

      <div className="mt-4">
        <form action="">
          <div className="space-y-4">
            <InputBox
              type="text"
              label="Meeting Room Name"
              placeholder={"Enter Meeting Room Name"}
              onChange={handleChange}
              name="name"
              value={formData.name}
              disabled={isProcessing}
            />

            <InputBox
              type="text"
              label="Description"
              placeholder={"e.g. 5 pax capacity"}
              onChange={handleChange}
              name="description"
              value={formData.description}
              disabled={isProcessing}
            />

            <div className="font-bold">Featues</div>
            <button
              type="button"
              onClick={() => {
                setFeatures([...features, blankObject]);
              }}
            >
              Add
            </button>
            {features.map((feature, index) => (
              <div className="" index={index}>
                <div className="font-semibold">Feature {index + 1}</div>
                <div className="flex items-center gap-4 flex-wrap">
                  <InputBox
                    type="text"
                    placeholder="Enter feature"
                    value={feature.name}
                    onChange={(e) => {
                      const newFeatures = [...features];
                      newFeatures[index].name = e.target.value;
                      setFeatures(newFeatures);
                    }}
                    name="name"
                  />
                  <InputBox
                    type="text"
                    placeholder="Enter value"
                    value={feature.value}
                    onChange={(e) => {
                      const newFeatures = [...features];
                      newFeatures[index].value = e.target.value;
                      setFeatures(newFeatures);
                    }}
                    name="value"
                  />
                  <button
                    className="underline"
                    type="button"
                    onClick={() => {
                      const newFeatures = [...features];
                      newFeatures.splice(index, 1);
                      setFeatures(newFeatures);
                    }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
          </div>

          <div className="mt-4">
            <div className="header">Upload Photos</div>

            <PhotosUploader
              data={formData.photos}
              setData={handleNewPhotos}
              primaryPhoto={primaryPhoto}
              setPrimaryPhoto={setPrimaryPhoto}
              uploader={handleUpload}
            />
          </div>

          <div className="mt-8 max-w-96">
            <button className="small-form-button" onClick={handleSubmit}>
              {isProcessing ? "Processing..." : "Submit"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
