import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import SelectBox from "../../components/SelectBox";
import { setDocData, getDocDataSnap } from "../../utils/firebase.utils";
import toast from "react-hot-toast";
import {
  Subject,
  Close,
  AccessTime,
  Person,
  Description,
  EventNote,
  Update,
} from "@mui/icons-material";

export default function RequestDetails({ data, setViewMode }) {
  const currentUser = useSelector((state) => state.userObject);
  const [updated, setUpdated] = React.useState(false);
  const [newStatus, setNewStatus] = React.useState("");
  const [formData, setFormData] = React.useState(data);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [comments, setComments] = React.useState(data.comments || []);
  const [newComment, setNewComment] = React.useState("");

  useEffect(() => {
    const fetchData = async () => {
      await getDocDataSnap("leaveRequests", data.id, (doc) => {
        setFormData(doc);
        setComments(doc.comments || []);
      });
    };
    fetchData();
  }, []);

  const statuses = ["Pending", "Approved", "Rejected", "Returned"];
  const statusOptions = statuses.map((status) => ({
    value: status,
    label: status,
  }));

  const handleSelectNewStatus = (status) => {
    setNewStatus(status.value);
    setUpdated(true);
  };

  const handleUpdate = async () => {
    setIsProcessing(true);
    const docObject = {
      ...formData,
      status: newStatus,
      updatedBy: currentUser.displayName,
      updatedEmail: currentUser.email,
      updatedAt: new Date().toLocaleString(),
      updateType: "status is updated to " + newStatus,
      approvedAt: newStatus === "Approved" ? new Date().toLocaleString() : "",
    };
    await setDocData("leaveRequests", data.id, docObject);
    toast.success("Status updated successfully");
    setIsProcessing(false);
    setUpdated(false);
    sendStatusUpdateNotification();
  };

  const sendStatusUpdateNotification = async () => {
    const notificationObject = {
      recipientEmail: data.requestedBy,
      recipientName: data.name,
      requestor: currentUser.email,
      subject: "Leave Request Status Update",
      message: `Your leave request status has been updated to ${newStatus}`,
      link: "https://myhr.leechiu.app/leaves",
    };

    const sendEmail = async () => {
      await fetch("https://hrms-emailerv1.vercel.app/api/notifications", {
        method: "POST",
        body: JSON.stringify(notificationObject),
      });
    };
    sendEmail();
  };

  const handleChange = (e) => {
    setNewComment(e.target.value);
  };

  const handleSubmit = async () => {
    setIsProcessing(true);
    const commentObject = {
      message: newComment,
      createdBy: currentUser.displayName,
      createdAt: new Date().toLocaleString(),
    };
    const docObject = {
      ...formData,
      comments: [...comments, commentObject],
    };
    await setDocData("leaveRequests", data.id, docObject);
    toast.success("Comment added successfully");
    setIsProcessing(false);
    setNewComment("");
  };

  // compute for number of days excluding weekends
  const getBusinessDays = (startDate, endDate) => {
    let count = 0;
    let curDate = new Date(startDate);
    while (curDate <= new Date(endDate)) {
      const dayOfWeek = curDate.getDay();
      if (![0, 6].includes(dayOfWeek)) {
        count++;
      }
      curDate.setDate(curDate.getDate() + 1);
    }
    return count;
  };

  return (
    <div className="min-h-screen bg-gray-50 p-6">
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="flex items-center justify-between mb-6">
          <h1 className="text-2xl font-bold text-leechiuBlue">
            Leave Request Details
          </h1>
          <button
            onClick={() => setViewMode(false)}
            className="p-2 hover:bg-gray-100 rounded-full transition-colors"
          >
            <Close className="text-gray-500" />
          </button>
        </div>

        <div className="flex flex-col lg:flex-row gap-6">
          {/* Main Details Card */}
          <div className="flex-1 bg-white rounded-xl shadow-sm p-6 space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Requestor */}
              <div className="flex items-start space-x-3">
                <Person className="text-leechiuBlue" />
                <div>
                  <p className="text-sm text-gray-500">Requestor</p>
                  <p className="font-medium">{data.name}</p>
                </div>
              </div>

              {/* Leave Type */}
              <div className="flex items-start space-x-3">
                <Subject className="text-leechiuBlue" />
                <div>
                  <p className="text-sm text-gray-500">Type of Leave</p>
                  <p className="font-medium">{data.leaveType}</p>
                </div>
              </div>

              {/* Duration */}
              <div className="flex items-start space-x-3">
                <AccessTime className="text-leechiuBlue" />
                <div>
                  <p className="text-sm text-gray-500">Duration</p>
                  <p className="font-medium">{data.numberOfDays} days</p>
                  <div className="text-sm text-gray-500">
                    {data.startDate} - {data.endDate}
                  </div>
                </div>
              </div>

              {/* Reason */}
              <div className="flex items-start space-x-3">
                <Description className="text-leechiuBlue" />
                <div>
                  <p className="text-sm text-gray-500">Reason</p>
                  <p className="font-medium">{data.reason}</p>
                </div>
              </div>

              {/* Status */}
              <div className="flex items-start space-x-3">
                <EventNote className="text-leechiuBlue" />
                <div>
                  <p className="text-sm text-gray-500">Status</p>
                  <span
                    className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium
                    ${
                      formData.status === "Approved"
                        ? "bg-green-100 text-green-800"
                        : formData.status === "Rejected"
                        ? "bg-red-100 text-red-800"
                        : formData.status === "Pending"
                        ? "bg-yellow-100 text-yellow-800"
                        : "bg-gray-100 text-gray-800"
                    }`}
                  >
                    {formData.status}
                  </span>
                  {formData.status === "Approved" && formData.approvedAt && (
                    <p className="text-xs text-gray-500 mt-1">
                      Approved on {formData.approvedAt}
                    </p>
                  )}
                </div>
              </div>

              {/* Approver */}
              <div className="flex items-start space-x-3">
                <Person className="text-leechiuOrange" />
                <div>
                  <p className="text-sm text-gray-500">Approver</p>
                  <p className="font-medium uppercase">
                    {data.approverName || "Not assigned"}
                  </p>
                  <p className="text-xs text-gray-500">{data.approver}</p>
                  {formData.updatedBy && formData.updatedAt && (
                    <p className="text-xs text-gray-500 mt-1">
                      Last updated by {formData.updatedBy} on{" "}
                      {formData.updatedAt}
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* Approver Action */}
            {data.approver === currentUser.email && (
              <div className="mt-8 p-4 bg-gray-50 rounded-lg border border-gray-100">
                <div className="flex items-center space-x-2 mb-4">
                  <Update className="text-leechiuBlue" />
                  <h3 className="font-medium">Update Status</h3>
                </div>
                <div className="space-y-4">
                  <SelectBox
                    label="New Status"
                    options={statusOptions}
                    placeholder="Select New Status"
                    handleSelect={(selected) => handleSelectNewStatus(selected)}
                  />
                  <button
                    className={`w-full md:w-auto px-4 py-2 rounded-lg font-medium transition-colors
                      ${
                        !updated
                          ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                          : "bg-leechiuBlue text-white hover:bg-leechiuBlue/90"
                      }`}
                    disabled={!updated}
                    onClick={handleUpdate}
                  >
                    {isProcessing ? "Processing..." : "Update Status"}
                  </button>
                </div>
              </div>
            )}
          </div>

          {/* Comments Section */}
          <div className="w-full lg:w-96 bg-white rounded-xl shadow-sm p-6">
            <h2 className="text-lg font-medium text-leechiuBlue mb-4">
              Comments
            </h2>

            {/* Add Comment */}
            <div className="space-y-4 mb-6">
              <textarea
                name="comment"
                rows={4}
                placeholder="Add your comment..."
                className="w-full rounded-lg p-3 border border-gray-200 focus:ring-2 focus:ring-leechiuBlue focus:border-transparent transition-all resize-none"
                onChange={handleChange}
                value={newComment}
              />
              <button
                className="w-full bg-leechiuBlue hover:bg-leechiuBlue/90 text-white font-medium px-4 py-2 rounded-lg transition-colors"
                onClick={handleSubmit}
                disabled={isProcessing || !newComment.trim()}
              >
                {isProcessing ? "Adding..." : "Add Comment"}
              </button>
            </div>

            {/* Comments List */}
            <div className="space-y-4">
              {comments.map((comment, index) => (
                <div
                  key={index}
                  className="p-4 bg-gray-50 rounded-lg space-y-2"
                >
                  <div className="flex items-center justify-between">
                    <span className="font-medium text-sm">
                      {comment.createdBy}
                    </span>
                    <span className="text-xs text-gray-500">
                      {comment.createdAt}
                    </span>
                  </div>
                  <p className="text-sm text-gray-700">{comment.message}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
