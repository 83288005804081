import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  FiSearch,
  FiUsers,
  FiTrendingUp,
  FiUserPlus,
  FiFileText,
  FiUser,
  FiMoreHorizontal,
  FiDownload,
  FiFilter,
  FiBarChart2,
  FiPieChart,
  FiCalendar,
  FiTrendingDown,
  FiActivity,
} from "react-icons/fi";
import { getAllDocsSnap } from "../utils/firebase.utils";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LineChart,
  Line,
  CartesianGrid,
  AreaChart,
  Area,
} from "recharts";
import EmployeeBreakdown from "./Dashboard/EmployeeBreakdown";
import PieChartComp from "../charts/PieChart";
import EmployeeDemographics from "./Dashboard/EmployeeDemographics";

// Custom chart components to replace the existing PieChartComp
const DonutChart = ({ data }) => {
  const COLORS = [
    "#2563eb", // Blue
    "#f97316", // Orange
    "#10b981", // Green
    "#8b5cf6", // Purple
    "#ec4899", // Pink
    "#f59e0b", // Amber
    "#06b6d4", // Cyan
    "#14b8a6", // Teal
    "#6366f1", // Indigo
    "#d946ef", // Fuchsia
  ];
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    name,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <PieChart>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={90}
          innerRadius={50}
          fill="#8884d8"
          dataKey="value"
          nameKey="name"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip formatter={(value) => [`${value} employees`, "Count"]} />
        <Legend layout="vertical" verticalAlign="middle" align="right" />
      </PieChart>
    </ResponsiveContainer>
  );
};

const HorizontalBarChart = ({ data }) => {
  const COLORS = [
    "#2563eb", // Blue
    "#f97316", // Orange
    "#10b981", // Green
    "#8b5cf6", // Purple
    "#ec4899", // Pink
    "#f59e0b", // Amber
    "#06b6d4", // Cyan
    "#14b8a6", // Teal
    "#6366f1", // Indigo
    "#d946ef", // Fuchsia
  ];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        layout="vertical"
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 80,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
        <XAxis type="number" hide />
        <YAxis
          dataKey="name"
          type="category"
          axisLine={false}
          tickLine={false}
          width={70}
        />
        <Tooltip formatter={(value) => [`${value} employees`, "Count"]} />
        <Bar dataKey="value" radius={[0, 4, 4, 0]}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

const TrendingLineChart = ({ departments, activeEmployees }) => {
  // Generate monthly department trend data from actual employee information
  const generateDepartmentTrends = () => {
    // Get the current date and calculate dates for the last 6 months
    const currentDate = new Date();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const currentMonth = currentDate.getMonth();

    // Create array of last 6 months
    const lastSixMonths = Array(6)
      .fill()
      .map((_, i) => {
        const monthIndex = (currentMonth - 5 + i + 12) % 12;
        const year =
          currentDate.getFullYear() - (monthIndex > currentMonth ? 1 : 0);
        return { month: monthNames[monthIndex], year, monthIndex };
      });

    // Get the top 3 departments by employee count for visualization clarity
    const topDepartments = [...departments]
      .sort((a, b) => b.total - a.total)
      .slice(0, 3)
      .map((dept) => dept.department);

    // Generate data for each month
    return lastSixMonths.map(({ month, year, monthIndex }) => {
      // Start with the month name
      const monthData = { month };

      // For each top department, count employees who were active in that month
      topDepartments.forEach((deptName) => {
        const deptEmployees = activeEmployees.filter((emp) => {
          // Only include employees in this department
          if (emp.department !== deptName) return false;

          // Check if employee started on or before this month
          if (!emp.startDate) return false;
          const startDate = new Date(emp.startDate);
          return (
            startDate.getFullYear() < year ||
            (startDate.getFullYear() === year &&
              startDate.getMonth() <= monthIndex)
          );
        });

        // Add department count to month data
        monthData[deptName] = deptEmployees.length;
      });

      // Also add total employees for that month
      monthData.total = activeEmployees.filter((emp) => {
        if (!emp.startDate) return false;
        const startDate = new Date(emp.startDate);
        return (
          startDate.getFullYear() < year ||
          (startDate.getFullYear() === year &&
            startDate.getMonth() <= monthIndex)
        );
      }).length;

      return monthData;
    });
  };

  // Generate the actual data
  const trendData = generateDepartmentTrends();

  // Get top 3 departments for legend
  const topDepartments = [...departments]
    .sort((a, b) => b.total - a.total)
    .slice(0, 3)
    .map((dept) => dept.department);

  // Define colors for departments
  const colors = {
    total: "#2563eb",
    [topDepartments[0]]: "#f97316",
    [topDepartments[1]]: "#10b981",
    [topDepartments[2]]: "#8b5cf6",
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        data={trendData}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <defs>
          {/* Create gradient fills for each line */}
          <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={colors.total} stopOpacity={0.8} />
            <stop offset="95%" stopColor={colors.total} stopOpacity={0.1} />
          </linearGradient>
          {topDepartments.map((dept, index) => (
            <linearGradient
              key={index}
              id={`color${dept.replace(/\s+/g, "")}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop offset="5%" stopColor={colors[dept]} stopOpacity={0.8} />
              <stop offset="95%" stopColor={colors[dept]} stopOpacity={0.1} />
            </linearGradient>
          ))}
        </defs>
        <XAxis dataKey="month" axisLine={false} tickLine={false} />
        <YAxis axisLine={false} tickLine={false} />
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <Tooltip />
        <Legend />

        {/* Lines for each department */}
        <Area
          type="monotone"
          dataKey="total"
          name="All Departments"
          stroke={colors.total}
          fillOpacity={1}
          fill="url(#colorTotal)"
        />
        {topDepartments.map((dept, index) => (
          <Area
            key={index}
            type="monotone"
            dataKey={dept}
            name={dept}
            stroke={colors[dept]}
            fillOpacity={0.5}
            fill={`url(#color${dept.replace(/\s+/g, "")})`}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

// Add a new demographic chart component
const DemographicsChart = ({ type, employees, birthDateData }) => {
  // Process data based on the selected demographic type
  let data = [];
  let colors = [];
  let title = "";

  if (type === "gender") {
    // Create gender distribution data
    const genderCount = {
      Male: 0,
      Female: 0,
      Other: 0,
    };

    employees.forEach((emp) => {
      const gender = emp.gender || "Other";
      genderCount[gender] = (genderCount[gender] || 0) + 1;
    });

    data = Object.keys(genderCount).map((gender) => ({
      name: gender,
      value: genderCount[gender],
    }));

    colors = ["#2563eb", "#ec4899", "#8b5cf6"];
    title = "Gender Distribution";
  } else if (type === "age") {
    // Create age group distribution
    const ageGroups = {
      "18-25": 0,
      "26-35": 0,
      "36-45": 0,
      "46-55": 0,
      "56+": 0,
    };

    // Check if we have the birth date array available
    if (birthDateData && birthDateData.length > 0) {
      // Process each entry in the birthdate array
      birthDateData.forEach((entry) => {
        if (!entry.value) return;

        try {
          // Parse the date in yyyy-mm-dd format
          const birthDateParts = entry.value.split("-");
          if (birthDateParts.length !== 3) return;

          const birthYear = parseInt(birthDateParts[0]);
          const birthMonth = parseInt(birthDateParts[1]) - 1; // JS months are 0-indexed
          const birthDay = parseInt(birthDateParts[2]);

          // Create valid date object
          const birthDate = new Date(birthYear, birthMonth, birthDay);

          // Calculate age
          const today = new Date();
          let age = today.getFullYear() - birthDate.getFullYear();

          // Adjust age if birthday hasn't occurred yet this year
          const monthDiff = today.getMonth() - birthDate.getMonth();
          if (
            monthDiff < 0 ||
            (monthDiff === 0 && today.getDate() < birthDate.getDate())
          ) {
            age--;
          }

          // Assign to appropriate age group
          if (age <= 25) ageGroups["18-25"]++;
          else if (age <= 35) ageGroups["26-35"]++;
          else if (age <= 45) ageGroups["36-45"]++;
          else if (age <= 55) ageGroups["46-55"]++;
          else ageGroups["56+"]++;
        } catch (error) {}
      });
    } else {
      employees.forEach((emp) => {
        if (!emp.birthDate) return;

        try {
          // Parse the date in yyyy-mm-dd format
          const birthDateParts = emp.birthDate.split("-");
          if (birthDateParts.length !== 3) return;

          const birthYear = parseInt(birthDateParts[0]);
          const birthMonth = parseInt(birthDateParts[1]) - 1; // JS months are 0-indexed
          const birthDay = parseInt(birthDateParts[2]);

          // Create valid date object
          const birthDate = new Date(birthYear, birthMonth, birthDay);

          // Calculate age
          const today = new Date();
          let age = today.getFullYear() - birthDate.getFullYear();

          // Adjust age if birthday hasn't occurred yet this year
          const monthDiff = today.getMonth() - birthDate.getMonth();
          if (
            monthDiff < 0 ||
            (monthDiff === 0 && today.getDate() < birthDate.getDate())
          ) {
            age--;
          }

          // Assign to appropriate age group
          if (age <= 25) ageGroups["18-25"]++;
          else if (age <= 35) ageGroups["26-35"]++;
          else if (age <= 45) ageGroups["36-45"]++;
          else if (age <= 55) ageGroups["46-55"]++;
          else ageGroups["56+"]++;
        } catch (error) {}
      });
    }

    data = Object.keys(ageGroups).map((group) => ({
      name: group,
      value: ageGroups[group],
    }));

    colors = ["#10b981", "#f97316", "#2563eb", "#8b5cf6", "#ec4899"];
    title = "Age Distribution";
  } else if (type === "tenure") {
    // Create tenure distribution
    const tenureGroups = {
      "< 1 year": 0,
      "1-2 years": 0,
      "3-5 years": 0,
      "5+ years": 0,
    };

    employees.forEach((emp) => {
      if (!emp.startDate) return;

      const startDate = new Date(emp.startDate);
      const currentDate = new Date();
      const tenure = Math.floor(
        (currentDate - startDate) / (365 * 24 * 60 * 60 * 1000)
      );

      if (tenure < 1) tenureGroups["< 1 year"]++;
      else if (tenure <= 2) tenureGroups["1-2 years"]++;
      else if (tenure <= 5) tenureGroups["3-5 years"]++;
      else tenureGroups["5+ years"]++;
    });

    data = Object.keys(tenureGroups).map((group) => ({
      name: group,
      value: tenureGroups[group],
    }));

    colors = ["#f97316", "#10b981", "#2563eb", "#8b5cf6"];
    title = "Employee Tenure";
  }

  return (
    <div className="h-full">
      <h3 className="text-sm font-medium text-gray-500 mb-2">{title}</h3>
      <div className="h-[200px]">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            margin={{ top: 5, right: 30, left: 0, bottom: 5 }}
            layout="vertical"
          >
            <CartesianGrid strokeDasharray="3 3" horizontal={false} />
            <XAxis type="number" hide />
            <YAxis
              dataKey="name"
              type="category"
              axisLine={false}
              tickLine={false}
              tick={{ fontSize: 12 }}
            />
            <Tooltip
              formatter={(value) => [
                `${value} employees (${Math.round(
                  (value / employees.length) * 100
                )}%)`,
                "Count",
              ]}
            />
            <Bar dataKey="value" radius={[0, 4, 4, 0]}>
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="mt-2 grid grid-cols-2 gap-2">
        {data.map((item, index) => (
          <div key={index} className="flex items-center">
            <div
              className={`w-3 h-3 rounded-full mr-2`}
              style={{ backgroundColor: colors[index % colors.length] }}
            ></div>
            <span className="text-xs text-gray-600">{item.name}: </span>
            <span className="text-xs font-medium ml-1">
              {Math.round((item.value / employees.length) * 100)}%
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

// Add a new component for Resignations and New Hires chart
const ResignationHiresChart = ({ allEmployees }) => {
  // Function to generate monthly data for resignations and new hires
  const generateMonthlyData = () => {
    const currentDate = new Date();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Create array of last 12 months
    const last12Months = Array(12)
      .fill()
      .map((_, i) => {
        const monthIndex = (currentMonth - 11 + i + 12) % 12;
        const year = currentYear - (monthIndex > currentMonth ? 1 : 0);
        return {
          month: monthNames[monthIndex],
          year,
          monthIndex,
          fullMonth: `${year}-${String(monthIndex + 1).padStart(2, "0")}`, // Format: YYYY-MM
        };
      });

    // Calculate hires and resignations for each month
    return last12Months.map(({ month, year, monthIndex, fullMonth }) => {
      // New hires this month (employees with startDate in this month/year)
      const newHires = allEmployees.filter((emp) => {
        if (!emp.startDate) return false;

        const startDate = new Date(emp.startDate);
        return (
          startDate.getFullYear() === year &&
          startDate.getMonth() === monthIndex
        );
      }).length;

      // Resignations this month (employees with endDate in this month/year and Resigned status)
      const resignations = allEmployees.filter((emp) => {
        if (!emp.endDate || emp.status !== "Resigned") return false;

        const endDate = new Date(emp.endDate);
        return (
          endDate.getFullYear() === year && endDate.getMonth() === monthIndex
        );
      }).length;

      return {
        month,
        fullMonth,
        newHires,
        resignations,
        netChange: newHires - resignations,
      };
    });
  };

  const chartData = generateMonthlyData();

  // Calculate totals for summary
  const totalNewHires = chartData.reduce(
    (sum, month) => sum + month.newHires,
    0
  );
  const totalResignations = chartData.reduce(
    (sum, month) => sum + month.resignations,
    0
  );
  const netGrowth = totalNewHires - totalResignations;

  // Calculate retention rate
  const retentionRate =
    allEmployees.length > 0
      ? (100 - (totalResignations / allEmployees.length) * 100).toFixed(1)
      : 100;

  return (
    <div className="h-full">
      <div className="mb-6 flex justify-between items-center">
        <div>
          <h3 className="text-sm font-medium text-gray-500">
            Workforce Changes (Last 12 Months)
          </h3>
          <div className="mt-2 flex space-x-4">
            <div className="flex items-center">
              <div className="w-3 h-3 rounded-full bg-green-500 mr-2"></div>
              <span className="text-xs text-gray-600">New Hires</span>
            </div>
            <div className="flex items-center">
              <div className="w-3 h-3 rounded-full bg-red-500 mr-2"></div>
              <span className="text-xs text-gray-600">Resignations</span>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 gap-2 text-xs">
          <div className="bg-green-50 rounded-lg p-2 border border-green-100">
            <div className="text-gray-500">New Hires</div>
            <div className="font-bold text-gray-800">{totalNewHires}</div>
          </div>
          <div className="bg-red-50 rounded-lg p-2 border border-red-100">
            <div className="text-gray-500">Resignations</div>
            <div className="font-bold text-gray-800">{totalResignations}</div>
          </div>
          <div className="bg-blue-50 rounded-lg p-2 border border-blue-100">
            <div className="text-gray-500">Retention Rate</div>
            <div className="font-bold text-gray-800">{retentionRate}%</div>
          </div>
        </div>
      </div>

      <div className="h-[300px]">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={chartData}
            margin={{ top: 10, right: 30, left: 0, bottom: 5 }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              vertical={false}
              stroke="#f0f0f0"
            />
            <XAxis dataKey="month" axisLine={false} tickLine={false} />
            <YAxis axisLine={false} tickLine={false} />
            <Tooltip
              formatter={(value, name) => {
                const label =
                  name === "newHires"
                    ? "New Hires"
                    : name === "resignations"
                    ? "Resignations"
                    : "Net Change";
                return [value, label];
              }}
            />
            <Legend />
            <Bar
              dataKey="newHires"
              name="New Hires"
              fill="#10b981"
              radius={[3, 3, 0, 0]}
            />
            <Bar
              dataKey="resignations"
              name="Resignations"
              fill="#ef4444"
              radius={[3, 3, 0, 0]}
            />
            <Line
              type="monotone"
              dataKey="netChange"
              name="Net Change"
              stroke="#2563eb"
              strokeWidth={2}
              dot={{ r: 4 }}
              activeDot={{ r: 6 }}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

const QuickAnalytics = ({ employees }) => {
  // Calculate average tenure
  const calculateAvgTenure = () => {
    const employeesWithStartDate = employees.filter(
      (emp) => emp.startDate && emp.status !== "Resigned"
    );
    if (employeesWithStartDate.length === 0) return 0;

    const totalTenure = employeesWithStartDate.reduce((acc, emp) => {
      const startDate = new Date(emp.startDate);
      const today = new Date();
      const tenureInYears = (today - startDate) / (365 * 24 * 60 * 60 * 1000);
      return acc + tenureInYears;
    }, 0);

    return (totalTenure / employeesWithStartDate.length).toFixed(1);
  };

  // Calculate resignation rate (last 12 months)
  const calculateResignationRate = () => {
    const today = new Date();
    const oneYearAgo = new Date(today.setFullYear(today.getFullYear() - 1));

    const resignationsLastYear = employees.filter((emp) => {
      if (emp.status !== "Resigned" || !emp.endDate) return false;
      const endDate = new Date(emp.endDate);
      return endDate >= oneYearAgo;
    }).length;

    const totalEmployeesInPeriod = employees.length;
    return totalEmployeesInPeriod > 0
      ? ((resignationsLastYear / totalEmployeesInPeriod) * 100).toFixed(1)
      : 0;
  };

  // Calculate growth rate (comparing current month to previous month)
  const calculateGrowthRate = () => {
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    // Get active employees count for current month
    const currentActive = employees.filter((emp) => {
      if (!emp.startDate) return false;
      const startDate = new Date(emp.startDate);
      if (emp.status === "Resigned" && emp.endDate) {
        const endDate = new Date(emp.endDate);
        return startDate <= today && endDate >= today;
      }
      return startDate <= today;
    }).length;

    // Get active employees count for previous month
    const previousMonthDate = new Date(currentYear, currentMonth - 1, 1);
    const previousActive = employees.filter((emp) => {
      if (!emp.startDate) return false;
      const startDate = new Date(emp.startDate);
      if (emp.status === "Resigned" && emp.endDate) {
        const endDate = new Date(emp.endDate);
        return startDate <= previousMonthDate && endDate >= previousMonthDate;
      }
      return startDate <= previousMonthDate;
    }).length;

    return previousActive > 0
      ? (((currentActive - previousActive) / previousActive) * 100).toFixed(1)
      : 0;
  };

  const avgTenure = calculateAvgTenure();
  const resignationRate = calculateResignationRate();
  const growthRate = calculateGrowthRate();

  return (
    <div className="mt-6 bg-gray-50 p-4 rounded-lg">
      <h3 className="font-medium text-gray-700 mb-2">Quick Analytics</h3>
      <div className="text-sm text-gray-600">
        <div className="flex justify-between py-1">
          <span>Avg. Tenure</span>
          <span className="font-medium">{avgTenure} years</span>
        </div>
        <div className="flex justify-between py-1">
          <span>Resignation Rate</span>
          <span
            className={`font-medium ${
              parseFloat(resignationRate) > 5
                ? "text-red-500"
                : "text-green-500"
            }`}
          >
            {resignationRate}%
          </span>
        </div>
        <div className="flex justify-between py-1">
          <span>Growth Rate</span>
          <span
            className={`font-medium ${
              parseFloat(growthRate) >= 0 ? "text-green-500" : "text-red-500"
            }`}
          >
            {growthRate}%
          </span>
        </div>
      </div>
    </div>
  );
};

// Add new LeaveAnalytics component
const LeaveAnalytics = () => {
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribeLeaves = getAllDocsSnap("leaveRequests", (data) => {
      setLeaveRequests(data);
    });

    const unsubscribeEmployees = getAllDocsSnap("employees", (data) => {
      setEmployees(data);
      setIsLoading(false);
    });

    return () => {
      unsubscribeLeaves();
      unsubscribeEmployees();
    };
  }, []);

  // Create email to department mapping
  const emailToDepartment = {};
  employees.forEach((emp) => {
    if (emp.email) {
      emailToDepartment[emp.email.toLowerCase()] = emp.department;
    }
  });

  const processLeaveData = () => {
    const currentYear = new Date().getFullYear();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Initialize monthly data
    const monthlyData = monthNames.map((month) => ({
      month,
      approved: 0,
      pending: 0,
      rejected: 0,
    }));

    // Count leave requests by month and status
    leaveRequests.forEach((leave) => {
      const startDate = new Date(leave.startDate);
      if (startDate.getFullYear() === currentYear) {
        const monthIndex = startDate.getMonth();
        const status = leave.status?.toLowerCase() || "pending";

        if (status === "approved") monthlyData[monthIndex].approved++;
        else if (status === "rejected") monthlyData[monthIndex].rejected++;
        else monthlyData[monthIndex].pending++;
      }
    });

    return monthlyData;
  };

  const processLeaveDaysData = () => {
    const currentYear = new Date().getFullYear();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    // Initialize monthly data with different leave types
    const monthlyData = monthNames.map((month) => ({
      month,
      sick: 0,
      vacation: 0,
      maternity: 0,
      paternity: 0,
      bereavement: 0,
      unpaid: 0,
    }));

    // Filter for only approved leaves first
    const approvedLeaves = leaveRequests.filter(
      (leave) => leave.status?.toLowerCase() === "approved"
    );

    approvedLeaves.forEach((leave) => {
      if (!leave.startDate || !leave.numberOfDays || !leave.leaveType) return;

      const startDate = new Date(leave.startDate);
      if (startDate.getFullYear() === currentYear) {
        const monthIndex = startDate.getMonth();

        // Map the leave type to the corresponding key in our data structure
        let leaveTypeKey = "";
        switch (leave.leaveType.toLowerCase()) {
          case "sick leave":
            leaveTypeKey = "sick";
            break;
          case "vacation leave":
            leaveTypeKey = "vacation";
            break;
          case "maternity leave":
            leaveTypeKey = "maternity";
            break;
          case "paternity leave":
            leaveTypeKey = "paternity";
            break;
          case "bereavement leave":
            leaveTypeKey = "bereavement";
            break;
          case "unpaid leave":
            leaveTypeKey = "unpaid";
            break;
          default:
            return; // Skip if not one of our tracked types
        }

        // Ensure we have a valid number by removing any non-numeric characters except decimal point
        const cleanedNumber = leave.numberOfDays
          .toString()
          .replace(/[^\d.]/g, "");
        const days = parseFloat(cleanedNumber);

        if (
          !isNaN(days) &&
          monthlyData[monthIndex] &&
          monthlyData[monthIndex][leaveTypeKey] !== undefined
        ) {
          // Round to 1 decimal place to avoid floating point issues
          monthlyData[monthIndex][leaveTypeKey] = Number(
            (monthlyData[monthIndex][leaveTypeKey] + days).toFixed(1)
          );
        }
      }
    });

    // Calculate totals for the stats display
    const totals = monthlyData.reduce((acc, month) => {
      Object.keys(month).forEach((key) => {
        if (key !== "month") {
          acc[key] = (acc[key] || 0) + month[key];
        }
      });
      return acc;
    }, {});

    // Round totals to 1 decimal place
    Object.keys(totals).forEach((key) => {
      if (key !== "month") {
        totals[key] = Number(totals[key].toFixed(1));
      }
    });

    return {
      monthlyData,
      totals,
    };
  };

  const calculateLeaveStats = () => {
    const total = leaveRequests.length;
    const approved = leaveRequests.filter(
      (leave) => leave.status?.toLowerCase() === "approved"
    ).length;
    const pending = leaveRequests.filter(
      (leave) => !leave.status || leave.status?.toLowerCase() === "pending"
    ).length;
    const rejected = leaveRequests.filter(
      (leave) => leave.status?.toLowerCase() === "rejected"
    ).length;

    // Get the processed leave days data
    const { totals: totalDays } = processLeaveDaysData();

    return {
      total,
      approved,
      pending,
      rejected,
      approvalRate: total ? ((approved / total) * 100).toFixed(1) : 0,
      totalDays,
    };
  };

  const stats = calculateLeaveStats();
  const chartData = processLeaveData();
  const { monthlyData: leaveDaysData } = processLeaveDaysData();

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-leechiuBlue"></div>
      </div>
    );
  }

  return (
    <div className="h-full">
      <div className="mb-6 flex justify-between items-center">
        <div>
          <h3 className="text-sm font-medium text-gray-500">Leave Analytics</h3>
        </div>
        <div className="grid grid-cols-4 gap-2 text-xs">
          <div className="bg-green-50 rounded-lg p-2 border border-green-100">
            <div className="text-gray-500">Approved</div>
            <div className="font-bold text-gray-800">{stats.approved}</div>
          </div>
          <div className="bg-yellow-50 rounded-lg p-2 border border-yellow-100">
            <div className="text-gray-500">Pending</div>
            <div className="font-bold text-gray-800">{stats.pending}</div>
          </div>
          <div className="bg-red-50 rounded-lg p-2 border border-red-100">
            <div className="text-gray-500">Rejected</div>
            <div className="font-bold text-gray-800">{stats.rejected}</div>
          </div>
          <div className="bg-blue-50 rounded-lg p-2 border border-blue-100">
            <div className="text-gray-500">Approval Rate</div>
            <div className="font-bold text-gray-800">{stats.approvalRate}%</div>
          </div>
        </div>
      </div>

      <div className="h-[300px]">
        <h3 className="text-sm font-medium text-gray-700 mb-4">
          Leave Days by Type
        </h3>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={leaveDaysData}
            margin={{ top: 10, right: 30, left: 0, bottom: 5 }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              vertical={false}
              stroke="#f0f0f0"
            />
            <XAxis dataKey="month" axisLine={false} tickLine={false} />
            <YAxis axisLine={false} tickLine={false} />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="sick"
              name="Sick Leave"
              fill="#ef4444"
              radius={[3, 3, 0, 0]}
              stackId="a"
            />
            <Bar
              dataKey="vacation"
              name="Vacation Leave"
              fill="#2563eb"
              radius={[3, 3, 0, 0]}
              stackId="a"
            />
            <Bar
              dataKey="maternity"
              name="Maternity Leave"
              fill="#ec4899"
              radius={[3, 3, 0, 0]}
              stackId="a"
            />
            <Bar
              dataKey="paternity"
              name="Paternity Leave"
              fill="#8b5cf6"
              radius={[3, 3, 0, 0]}
              stackId="a"
            />
            <Bar
              dataKey="bereavement"
              name="Bereavement Leave"
              fill="#f59e0b"
              radius={[3, 3, 0, 0]}
              stackId="a"
            />
            <Bar
              dataKey="unpaid"
              name="Unpaid Leave"
              fill="#6b7280"
              radius={[3, 3, 0, 0]}
              stackId="a"
            />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="mt-6 grid grid-cols-3 gap-4">
        {Object.entries(stats.totalDays).map(([type, days]) => {
          const leaveTypeNames = {
            sick: "Sick Leave",
            vacation: "Vacation Leave",
            maternity: "Maternity Leave",
            paternity: "Paternity Leave",
            bereavement: "Bereavement Leave",
            unpaid: "Unpaid Leave",
          };
          return (
            <div
              key={type}
              className="bg-gray-50 rounded-lg p-3 border border-gray-100"
            >
              <div className="text-sm text-gray-500">
                {leaveTypeNames[type] || type}
              </div>
              <div className="text-lg font-bold text-gray-800">{days} days</div>
              <div className="text-xs text-gray-500">Total this year</div>
            </div>
          );
        })}
      </div>

      {/* Add Top 10 Longest Leaves Table */}
      <div className="mt-8">
        <h3 className="text-lg font-semibold text-gray-800 mb-4">
          Upcoming Long Leaves
        </h3>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 border border-gray-200 rounded-lg">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Employee
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Leave Type
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Duration (Days)
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Start Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {leaveRequests
                .filter((leave) => {
                  // Check for valid number of days
                  if (!leave.numberOfDays || isNaN(Number(leave.numberOfDays)))
                    return false;

                  // Filter out rejected leaves
                  if (leave.status?.toLowerCase() === "rejected") return false;

                  // Filter for upcoming leaves only
                  const startDate = new Date(leave.startDate);
                  const today = new Date();
                  today.setHours(0, 0, 0, 0); // Reset time to start of day for fair comparison

                  return startDate >= today;
                })
                .sort((a, b) => Number(b.numberOfDays) - Number(a.numberOfDays))
                .slice(0, 10)
                .map((leave, index) => {
                  // Get first name and last name from email (before the @ symbol)
                  const emailName = leave.requestedBy?.split("@")[0] || "";
                  // Convert email format (e.g., john.doe) to proper name format (e.g., John Doe)
                  const formattedName = emailName
                    .split(".")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ");

                  return (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="flex-shrink-0 h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center">
                            <span className="text-sm font-medium text-gray-600">
                              {formattedName.charAt(0) || "U"}
                            </span>
                          </div>
                          <div className="ml-4">
                            <div className="text-sm font-medium text-gray-900">
                              {formattedName || "Unknown"}
                            </div>
                            <div className="text-sm text-gray-500">
                              {emailToDepartment[
                                leave.requestedBy?.toLowerCase()
                              ] || "N/A"}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span
                          className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full 
                          ${
                            leave.leaveType?.toLowerCase().includes("sick")
                              ? "bg-red-100 text-red-800"
                              : leave.leaveType
                                  ?.toLowerCase()
                                  .includes("vacation")
                              ? "bg-blue-100 text-blue-800"
                              : leave.leaveType
                                  ?.toLowerCase()
                                  .includes("maternity")
                              ? "bg-pink-100 text-pink-800"
                              : leave.leaveType
                                  ?.toLowerCase()
                                  .includes("paternity")
                              ? "bg-purple-100 text-purple-800"
                              : leave.leaveType
                                  ?.toLowerCase()
                                  .includes("bereavement")
                              ? "bg-yellow-100 text-yellow-800"
                              : "bg-gray-100 text-gray-800"
                          }`}
                        >
                          {leave.leaveType || "Not specified"}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 font-medium">
                        {leave.numberOfDays} days
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {new Date(leave.startDate).toLocaleDateString()}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span
                          className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full
                          ${
                            leave.status?.toLowerCase() === "approved"
                              ? "bg-green-100 text-green-800"
                              : leave.status?.toLowerCase() === "rejected"
                              ? "bg-red-100 text-red-800"
                              : "bg-yellow-100 text-yellow-800"
                          }`}
                        >
                          {leave.status || "Pending"}
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

// Add the WorkforceForecast component before the AdminDashboard component
const WorkforceForecast = ({ leaveRequests, activeEmployees }) => {
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedMonthData, setSelectedMonthData] = useState([]);

  const generateForecastData = () => {
    const currentDate = new Date();
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    // Get all departments
    const departments = [
      ...new Set(activeEmployees.map((emp) => emp.department)),
    ];

    // Create email to department mapping
    const emailToDepartment = {};
    activeEmployees.forEach((emp) => {
      if (emp.email) {
        emailToDepartment[emp.email.toLowerCase()] = emp.department;
      }
    });

    // Get next 6 months
    const next6Months = Array(6)
      .fill()
      .map((_, index) => {
        const monthIndex = (currentMonth + index) % 12;
        const year = currentYear + Math.floor((currentMonth + index) / 12);
        return {
          month: monthNames[monthIndex],
          monthIndex,
          year,
          fullDate: new Date(year, monthIndex, 1),
        };
      });

    // Initialize forecast data
    const forecastData = next6Months.map(({ month, monthIndex, year }) => {
      const monthData = {
        month,
        monthIndex,
        year,
      };

      // For each department
      departments.forEach((dept) => {
        // Find approved leaves for this department in this month
        const employeesOnLeave = new Set();

        leaveRequests.forEach((leave) => {
          if (leave.status?.toLowerCase() !== "approved") return;

          const startDate = new Date(leave.startDate);
          if (
            startDate.getFullYear() === year &&
            startDate.getMonth() === monthIndex
          ) {
            // Get department using email from requestedBy
            const employeeEmail = leave.requestedBy?.toLowerCase();
            const employeeDepartment = emailToDepartment[employeeEmail];

            if (employeeDepartment === dept) {
              employeesOnLeave.add(employeeEmail);
            }
          }
        });

        // Store number of employees on leave
        monthData[dept] = employeesOnLeave.size;
      });

      return monthData;
    });

    return { forecastData, departments };
  };

  const getDetailedMonthData = (monthData) => {
    console.log("Processing month data:", monthData);

    if (!monthData) {
      console.error("No month data provided");
      return [];
    }

    const monthIndex = monthData.monthIndex;
    const year = monthData.year;

    if (monthIndex === undefined || year === undefined) {
      console.error("Invalid month data:", monthData);
      return [];
    }

    // Create email to department and name mappings
    const emailToDepartment = {};
    const emailToName = {};
    activeEmployees.forEach((emp) => {
      if (emp.email) {
        const email = emp.email.toLowerCase();
        emailToDepartment[email] = emp.department;
        emailToName[email] = `${emp.firstName} ${emp.lastName}`;
      }
    });

    // Get all leaves for this month
    const monthLeaves = leaveRequests.filter((leave) => {
      if (!leave.startDate || leave.status?.toLowerCase() !== "approved")
        return false;
      const startDate = new Date(leave.startDate);
      return (
        startDate.getFullYear() === year && startDate.getMonth() === monthIndex
      );
    });

    // Group leaves by employee email
    const employeeLeaves = monthLeaves.reduce((acc, leave) => {
      const email = leave.requestedBy?.toLowerCase();
      if (!acc[email]) {
        acc[email] = {
          name: emailToName[email] || "Unknown",
          department: emailToDepartment[email] || "Unknown",
          leaves: [],
        };
      }
      acc[email].leaves.push({
        startDate: new Date(leave.startDate).toLocaleDateString(),
        numberOfDays: leave.numberOfDays,
        leaveType: leave.leaveType || "Not specified",
      });
      return acc;
    }, {});

    // Convert to array and format for display
    const detailedData = Object.values(employeeLeaves).map(
      ({ name, department, leaves }) => ({
        name,
        department,
        startDate: leaves.map((l) => l.startDate).join(", "),
        numberOfDays: leaves.reduce(
          (sum, l) => sum + Number(l.numberOfDays),
          0
        ),
        leaveType: leaves.map((l) => l.leaveType).join(", "),
      })
    );

    return detailedData.sort((a, b) => {
      if (a.department === b.department) {
        return a.name.localeCompare(b.name);
      }
      return a.department.localeCompare(b.department);
    });
  };

  const handleClick = (event, payload) => {
    console.log("Click event:", event);
    console.log("Click payload:", payload);

    // Handle click on data point
    if (payload && payload.payload) {
      console.log("Data point click - payload:", payload.payload);
      const detailedData = getDetailedMonthData(payload.payload);
      setSelectedMonth(payload.payload.month);
      setSelectedMonthData(detailedData);
      setShowModal(true);
      return;
    }

    // Handle click on chart area
    if (event && event.activePayload && event.activePayload[0]) {
      const monthData = event.activePayload[0].payload;
      console.log("Chart click - month data:", monthData);
      const detailedData = getDetailedMonthData(monthData);
      setSelectedMonth(monthData.month);
      setSelectedMonthData(detailedData);
      setShowModal(true);
      return;
    }

    console.log("No valid data found in click event");
  };

  const { forecastData, departments } = generateForecastData();
  console.log("Generated forecast data:", forecastData);

  // Define colors array for department lines
  const colors = [
    "#2563eb",
    "#f97316",
    "#10b981",
    "#8b5cf6",
    "#ec4899",
    "#f59e0b",
  ];

  // Calculate max value for Y-axis
  const maxOnLeave = Math.max(
    ...forecastData.flatMap((data) =>
      departments.map((dept) => data[dept] || 0)
    )
  );

  return (
    <div className="h-full">
      <div
        className="font-medium text-lg text-gray-900
      "
      >
        Manpower Forecast
      </div>
      <div className="h-[300px]">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={forecastData}
            margin={{ top: 10, right: 30, left: 0, bottom: 5 }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              vertical={false}
              stroke="#f0f0f0"
            />
            <XAxis dataKey="month" axisLine={false} tickLine={false} />
            <YAxis
              axisLine={false}
              tickLine={false}
              domain={[0, maxOnLeave + 1]}
              tickFormatter={(value) => Math.round(value)}
            />
            <Tooltip
              formatter={(value, name) => [`${value} employees on leave`, name]}
              labelStyle={{ color: "#374151" }}
              cursor={{ strokeDasharray: "3 3" }}
            />
            <Legend />
            {departments.map((dept, index) => (
              <Line
                key={dept}
                type="monotone"
                dataKey={dept}
                name={dept}
                stroke={colors[index % colors.length]}
                strokeWidth={2}
                dot={{ r: 4 }}
                activeDot={{ r: 6 }}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>

      {/* Month buttons */}
      <div className="mt-6 grid grid-cols-6 gap-4">
        {forecastData.map((monthData, index) => {
          // Calculate total employees on leave for this month
          const totalOnLeave = departments.reduce(
            (sum, dept) => sum + (monthData[dept] || 0),
            0
          );

          return (
            <div key={index} className="text-center">
              <div className="text-sm font-medium text-gray-900 mb-1">
                {monthData.month}
              </div>
              <div className="text-xs text-gray-500 mb-2">
                {totalOnLeave} on leave
              </div>
              <button
                onClick={() => {
                  const detailedData = getDetailedMonthData(monthData);
                  setSelectedMonth(monthData.month);
                  setSelectedMonthData(detailedData);
                  setShowModal(true);
                }}
                className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-leechiuBlue"
              >
                View Details
              </button>
            </div>
          );
        })}
      </div>

      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl shadow-lg w-3/4 max-h-[80vh] overflow-hidden">
            <div className="p-6 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-bold text-gray-800">
                  Leave Details - {selectedMonth}
                </h3>
                <button
                  onClick={() => setShowModal(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="p-6 overflow-auto max-h-[calc(80vh-120px)]">
              {selectedMonthData.length > 0 ? (
                <div className="space-y-4">
                  {Object.entries(
                    selectedMonthData.reduce((acc, leave) => {
                      if (!acc[leave.department]) {
                        acc[leave.department] = [];
                      }
                      acc[leave.department].push(leave);
                      return acc;
                    }, {})
                  ).map(([department, leaves], index) => (
                    <div key={department} className="border rounded-lg">
                      <button
                        onClick={(e) => {
                          const panel = e.currentTarget.nextElementSibling;
                          const arrow = e.currentTarget.querySelector("svg");

                          if (
                            panel.style.maxHeight &&
                            panel.style.maxHeight !== "0px"
                          ) {
                            // Collapse
                            panel.style.maxHeight = "0px";
                            arrow.classList.remove("rotate-180");
                          } else {
                            // Expand
                            panel.style.maxHeight = `${panel.scrollHeight}px`;
                            arrow.classList.add("rotate-180");
                          }
                        }}
                        className="w-full px-6 py-4 flex items-center justify-between bg-gray-50 hover:bg-gray-100 transition-colors rounded-lg focus:outline-none"
                      >
                        <div className="flex items-center space-x-4">
                          <h4 className="text-base font-semibold text-gray-800">
                            {department}
                          </h4>
                          <span className="px-2 py-1 bg-blue-100 text-blue-800 text-xs rounded-full">
                            {leaves.length} employees
                          </span>
                        </div>
                        <div className="flex items-center space-x-4">
                          <div className="text-sm text-gray-600">
                            Total Days:{" "}
                            {leaves.reduce(
                              (sum, leave) => sum + Number(leave.numberOfDays),
                              0
                            )}
                          </div>
                          <svg
                            className="w-5 h-5 text-gray-500 transform transition-transform duration-200"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M19 9l-7 7-7-7"
                            />
                          </svg>
                        </div>
                      </button>
                      <div
                        className="department-panel overflow-hidden transition-all duration-200"
                        style={{ maxHeight: "0px" }}
                      >
                        <div className="p-4">
                          {/* Department Summary */}
                          <div className="mb-4 grid grid-cols-3 gap-4">
                            <div className="bg-gray-50 rounded-lg p-3">
                              <div className="text-sm text-gray-600">
                                Average Leave Duration
                              </div>
                              <div className="text-lg font-semibold text-gray-800">
                                {(
                                  leaves.reduce(
                                    (sum, leave) =>
                                      sum + Number(leave.numberOfDays),
                                    0
                                  ) / leaves.length
                                ).toFixed(1)}{" "}
                                days
                              </div>
                            </div>
                            <div className="bg-gray-50 rounded-lg p-3">
                              <div className="text-sm text-gray-600">
                                Most Common Leave Type
                              </div>
                              <div className="text-lg font-semibold text-gray-800">
                                {
                                  Object.entries(
                                    leaves.reduce((acc, leave) => {
                                      acc[leave.leaveType] =
                                        (acc[leave.leaveType] || 0) + 1;
                                      return acc;
                                    }, {})
                                  ).sort((a, b) => b[1] - a[1])[0][0]
                                }
                              </div>
                            </div>
                            <div className="bg-gray-50 rounded-lg p-3">
                              <div className="text-sm text-gray-600">
                                Total Leave Days
                              </div>
                              <div className="text-lg font-semibold text-gray-800">
                                {leaves.reduce(
                                  (sum, leave) =>
                                    sum + Number(leave.numberOfDays),
                                  0
                                )}{" "}
                                days
                              </div>
                            </div>
                          </div>

                          {/* Department Leave Table */}
                          <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200">
                              <thead className="bg-gray-50">
                                <tr>
                                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Employee Name
                                  </th>
                                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Start Date
                                  </th>
                                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Days
                                  </th>
                                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Leave Type
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="bg-white divide-y divide-gray-200">
                                {leaves.map((leave, index) => (
                                  <tr key={index} className="hover:bg-gray-50">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                      {leave.name}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {leave.startDate}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {leave.numberOfDays}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                      {leave.leaveType}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="text-center py-8 text-gray-500">
                  No leaves found for this month
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

function AdminDashboard() {
  const [employees, setEmployees] = React.useState([]);
  const [activeEmployees, setActiveEmployees] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [probationary, setProbationary] = React.useState([]);
  const [pendingApproval, setPendingApproval] = React.useState([]);
  const [newEmployees, setNewEmployees] = React.useState([]);
  const [chartType, setChartType] = useState("donut");
  const [timeRange, setTimeRange] = useState("month");
  const [demographicType, setDemographicType] = useState("gender");
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [showProbationaryModal, setShowProbationaryModal] = useState(false);

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const currentDay = currentDate.getDate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      // Fetch employees
      await getAllDocsSnap("employees", (data) => {
        setEmployees(data);
        //arrange data by lastName
        data.sort((a, b) => {
          if (a.lastName < b.lastName) {
            return -1;
          }
          if (a.lastName > b.lastName) {
            return 1;
          }
          return 0;
        });

        setEmployees(data);

        //filter not resigned and not pending approval
        const active = data.filter(
          (employee) =>
            employee.status !== "Resigned" &&
            employee.status !== "pending approval"
        );

        const proby = data.filter(
          (employee) => employee.status.toLowerCase() === "probationary"
        );

        const pending = data.filter(
          (employee) => employee.status.toLowerCase() === "pending approval"
        );

        const newEmp = active.filter((data) => {
          const year = data.startDate.split("-")[0];
          const month = data.startDate.split("-")[1];

          return year === currentYear && month === currentMonth;
        });

        setNewEmployees(newEmp);
        setPendingApproval(pending);
        setProbationary(proby);
        setActiveEmployees(active);
      });

      // Fetch leave requests
      await getAllDocsSnap("leaveRequests", (data) => {
        setLeaveRequests(data);
        setIsLoading(false);
      });
    };

    fetchData();
  }, []);

  const activeEmployeesBirthDate = activeEmployees.map((emp) => {
    return {
      name: emp?.displayName,
      id: emp?.id,
      value: emp?.birthdate,
    };
  });

  // Calculate regularization date (6 months from start date)
  const calculateRegularizationDate = (startDate) => {
    const date = new Date(startDate);
    date.setMonth(date.getMonth() + 6);
    return date.toLocaleDateString();
  };

  // Sort probationary employees by regularization date
  const sortedProbationary = [...probationary].sort((a, b) => {
    const dateA = new Date(calculateRegularizationDate(a.startDate));
    const dateB = new Date(calculateRegularizationDate(b.startDate));
    return dateA - dateB;
  });

  const empSummaryData = [
    {
      title: "Active Employees",
      value: activeEmployees.length,
      increase: "",
      icon: <FiUsers className="text-white text-xl" />,
      bgColor: "bg-leechiuBlue",
      onClick: null,
    },
    {
      title: "Probationary",
      value: probationary.length,
      increase: "",
      icon: <FiTrendingUp className="text-white text-xl" />,
      bgColor: "bg-leechiuOrange",
      onClick: () => setShowProbationaryModal(true),
    },
    {
      title: "New Employees",
      value: newEmployees.length,
      increase: "",
      icon: <FiUserPlus className="text-white text-xl" />,
      bgColor: "bg-green-500",
      onClick: null,
    },
  ];

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-leechiuBlue"></div>
      </div>
    );
  }

  const AllDepartments = activeEmployees.map((employee) => employee.department);
  const uniqueDepartments = [...new Set(AllDepartments)];

  const breakdownPerDepartment = uniqueDepartments.map((department) => {
    const employeesInDepartment = activeEmployees.filter(
      (employee) => employee.department === department
    );
    return {
      department: department,
      total: employeesInDepartment.length,
      employees: employeesInDepartment,
    };
  });

  // sort by total
  breakdownPerDepartment.sort((a, b) => b.total - a.total);

  const revenueDepartments = [
    "Commercial Leasing",
    "Investment Sales",
    "Residential",
    "HTL",
    "Research",
  ];

  const businessSupportDepartments = uniqueDepartments.filter(
    (dept) => !revenueDepartments.includes(dept)
  );

  const sumOfBusinessSupport = businessSupportDepartments.reduce(
    (acc, dept) => {
      const employeesInDepartment = activeEmployees.filter(
        (employee) => employee.department === dept
      );
      return acc + employeesInDepartment.length;
    },
    0
  );

  const summaryDeptData = [
    ...revenueDepartments.map((dept) => {
      const employeesInDepartment = activeEmployees.filter(
        (employee) => employee.department === dept
      );
      return {
        department: dept,
        total: employeesInDepartment.length,
      };
    }),
    {
      department: "Business Support",
      total: sumOfBusinessSupport,
    },
  ];

  const newData = summaryDeptData.map((item) => {
    return {
      name: item.department,
      value: item.total,
    };
  });

  // Generate monthly data based on actual employee info
  const generateMonthlyData = () => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const currentMonthIndex = currentDate.getMonth();

    // Get previous 6 months (including current)
    const last6Months = Array(6)
      .fill()
      .map((_, i) => {
        const monthIndex = (currentMonthIndex - 5 + i + 12) % 12;
        return {
          month: monthNames[monthIndex],
          monthIndex,
          year: currentYear - (monthIndex > currentMonthIndex ? 1 : 0),
        };
      });

    // Calculate employee counts for each month - use activeEmployees instead of all employees
    return last6Months.map(({ month, monthIndex, year }) => {
      // Filter employees that were active in this month
      const activeThisMonth = activeEmployees.filter((emp) => {
        if (!emp.startDate) return false;

        const startDate = new Date(emp.startDate);
        const startYear = startDate.getFullYear();
        const startMonth = startDate.getMonth();

        // Only count employees who started on or before this month
        return (
          startYear < year || (startYear === year && startMonth <= monthIndex)
        );
      });

      // Revenue departments employees
      const revenueThisMonth = activeThisMonth.filter((emp) =>
        revenueDepartments.includes(emp.department)
      );

      // Support departments (everyone else)
      const supportThisMonth = activeThisMonth.filter(
        (emp) => !revenueDepartments.includes(emp.department)
      );

      return {
        month,
        total: activeThisMonth.length,
        revenue: revenueThisMonth.length,
        support: supportThisMonth.length,
      };
    });
  };

  // Generate the chart data using actual employee info
  const employeeGrowthData = generateMonthlyData();

  // Calculate growth percentages
  const calculateGrowth = (data) => {
    if (data.length < 2) return 0;
    const current = data[data.length - 1];
    const previous = data[data.length - 2];

    if (previous === 0) return 0;
    return (((current - previous) / previous) * 100).toFixed(1);
  };

  // Calculate growth percentages for the stats cards
  const totalGrowth = calculateGrowth(employeeGrowthData.map((d) => d.total));
  const revenueGrowth = calculateGrowth(
    employeeGrowthData.map((d) => d.revenue)
  );
  const supportGrowth = calculateGrowth(
    employeeGrowthData.map((d) => d.support)
  );

  // Get current numbers
  const currentStats =
    employeeGrowthData.length > 0
      ? employeeGrowthData[employeeGrowthData.length - 1]
      : { total: 0, revenue: 0, support: 0 };

  return (
    <div className="bg-gray-50 min-h-screen p-6">
      {/* header portion */}
      <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
        <div className="flex items-center justify-between flex-wrap">
          <div className="flex flex-col items-start justify-start mb-4 md:mb-0">
            <h1 className="text-2xl font-bold text-leechiuBlue">
              Admin Dashboard
            </h1>
            <p className="text-gray-500">Welcome back! Here's your overview</p>
          </div>
          <div className="flex items-center gap-4">
            <div className="bg-gray-50 rounded-lg p-1 flex border border-gray-100">
              <button className="flex items-center px-3 py-1.5 rounded-lg text-sm transition-all bg-white shadow-sm text-leechiuBlue">
                <FiCalendar className="mr-2" />
                Today
              </button>
            </div>
            <div className="relative">
              <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              <input
                className="w-64 h-10 pl-10 pr-4 bg-gray-100 border-0 rounded-lg focus:outline-none focus:ring-2 focus:ring-leechiuBlue/30 transition-all"
                type="text"
                placeholder="Search employees, departments..."
              />
            </div>
          </div>
        </div>
      </div>

      {/* Stats cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
        {empSummaryData.map((data, index) => (
          <div
            key={index}
            className={`bg-white rounded-xl shadow-sm p-6 transition-all hover:shadow-md ${
              data.onClick ? "cursor-pointer" : ""
            }`}
            onClick={data.onClick}
          >
            <div className="flex items-start justify-between">
              <div>
                <h3 className="text-gray-500 font-medium">{data.title}</h3>
                <p className="text-3xl font-bold mt-2 text-gray-800">
                  {data.value}
                </p>
                <div className="mt-2 text-xs font-medium text-gray-400">
                  Compared to last month:
                  <span className="text-green-500 ml-1">
                    +{Math.floor(Math.random() * 10)}%
                  </span>
                </div>
              </div>
              <div className={`${data.bgColor} p-3 rounded-lg`}>
                {data.icon}
              </div>
            </div>
            <div className="mt-4 h-1 bg-gray-100 rounded-full overflow-hidden">
              <div
                className={`h-full ${data.bgColor}`}
                style={{ width: `${Math.min(data.value * 5, 100)}%` }}
              ></div>
            </div>
          </div>
        ))}
      </div>

      {/* Probationary Modal */}
      {showProbationaryModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl shadow-lg w-3/4 max-h-[80vh] overflow-hidden">
            <div className="p-6 border-b border-gray-200">
              <div className="flex items-center justify-between">
                <h3 className="text-lg font-bold text-gray-800">
                  Probationary Employees
                </h3>
                <button
                  onClick={() => setShowProbationaryModal(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="p-6 overflow-auto max-h-[calc(80vh-120px)]">
              <div className="overflow-x-auto">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Employee
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Department
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Start Date
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Regularization Date
                      </th>
                      <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                        Days Until Regularization
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {sortedProbationary.map((employee, index) => {
                      const regularizationDate = new Date(
                        calculateRegularizationDate(employee.startDate)
                      );
                      const today = new Date();
                      const daysUntilRegularization = Math.ceil(
                        (regularizationDate - today) / (1000 * 60 * 60 * 24)
                      );

                      return (
                        <tr
                          key={index}
                          className={
                            index % 2 === 0 ? "bg-white" : "bg-gray-50"
                          }
                        >
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center">
                                <span className="text-sm font-medium text-gray-600">
                                  {employee.firstName?.charAt(0)}
                                  {employee.lastName?.charAt(0)}
                                </span>
                              </div>
                              <div className="ml-4">
                                <div className="text-sm font-medium text-gray-900">
                                  {employee.firstName} {employee.lastName}
                                </div>
                                <div className="text-sm text-gray-500">
                                  {employee.email}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {employee.department}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {new Date(employee.startDate).toLocaleDateString()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {regularizationDate.toLocaleDateString()}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span
                              className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                daysUntilRegularization <= 30
                                  ? "bg-red-100 text-red-800"
                                  : daysUntilRegularization <= 60
                                  ? "bg-yellow-100 text-yellow-800"
                                  : "bg-green-100 text-green-800"
                              }`}
                            >
                              {daysUntilRegularization} days
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Main content */}
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-6">
        {/* Department breakdown */}
        <div className="lg:col-span-6 bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-lg font-bold text-gray-800">
              Department Breakdown
            </h2>
            <div className="flex items-center space-x-2">
              <select
                className="bg-gray-100 border-0 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-leechiuBlue/30 text-sm"
                onChange={(e) => setTimeRange(e.target.value)}
                value={timeRange}
              >
                <option value="week">This Week</option>
                <option value="month">This Month</option>
                <option value="quarter">This Quarter</option>
                <option value="year">This Year</option>
              </select>
              <button className="bg-gray-100 p-2 rounded-lg hover:bg-gray-200 transition-colors">
                <FiFilter className="text-gray-600" />
              </button>
              <button className="bg-gray-100 p-2 rounded-lg hover:bg-gray-200 transition-colors">
                <FiDownload className="text-gray-600" />
              </button>
            </div>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full">
              <thead>
                <tr className="bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <th className="px-4 py-3 rounded-tl-lg">Department</th>
                  <th className="px-4 py-3">Employees</th>
                  <th className="px-4 py-3 rounded-tr-lg">Percentage</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {breakdownPerDepartment.map((dept, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-50 transition-colors"
                  >
                    <td className="px-4 py-3 whitespace-nowrap text-sm font-medium text-gray-900 flex items-center">
                      <div
                        className={`w-3 h-3 rounded-full mr-2 ${
                          index % 3 === 0
                            ? "bg-leechiuBlue"
                            : index % 3 === 1
                            ? "bg-leechiuOrange"
                            : "bg-green-500"
                        }`}
                      ></div>
                      {dept.department}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-800">
                      {dept.total} employees
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-800">
                      <div className="flex items-center">
                        <div className="w-full bg-gray-200 rounded-full h-2 mr-2">
                          <div
                            className={`h-2 rounded-full ${
                              index % 3 === 0
                                ? "bg-leechiuBlue"
                                : index % 3 === 1
                                ? "bg-leechiuOrange"
                                : "bg-green-500"
                            }`}
                            style={{
                              width: `${
                                (dept.total / activeEmployees.length) * 100
                              }%`,
                            }}
                          ></div>
                        </div>
                        <span>
                          {Math.round(
                            (dept.total / activeEmployees.length) * 100
                          )}
                          %
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Department distribution - REPLACED */}
        <div className="lg:col-span-6 bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-lg font-bold text-gray-800">
              Department Distribution
            </h2>
            <div className="flex items-center space-x-3">
              <div className="bg-gray-100 rounded-lg p-1 flex">
                <button
                  className={`flex items-center px-3 py-1.5 rounded-lg text-sm transition-all ${
                    chartType === "donut"
                      ? "bg-white shadow-sm text-leechiuBlue"
                      : "text-gray-600"
                  }`}
                  onClick={() => setChartType("donut")}
                >
                  <FiPieChart className="mr-1" />
                  Donut
                </button>
                <button
                  className={`flex items-center px-3 py-1.5 rounded-lg text-sm transition-all ${
                    chartType === "bar"
                      ? "bg-white shadow-sm text-leechiuBlue"
                      : "text-gray-600"
                  }`}
                  onClick={() => setChartType("bar")}
                >
                  <FiBarChart2 className="mr-1" />
                  Bar
                </button>
                <button
                  className={`flex items-center px-3 py-1.5 rounded-lg text-sm transition-all ${
                    chartType === "trend"
                      ? "bg-white shadow-sm text-leechiuBlue"
                      : "text-gray-600"
                  }`}
                  onClick={() => setChartType("trend")}
                >
                  <FiActivity className="mr-1" />
                  Trend
                </button>
              </div>
              <button className="text-leechiuBlue hover:text-leechiuOrange transition-colors">
                <FiMoreHorizontal className="text-xl" />
              </button>
            </div>
          </div>

          <div className="h-[350px]">
            {chartType === "donut" && <DonutChart data={newData} />}
            {chartType === "bar" && <HorizontalBarChart data={newData} />}
            {chartType === "trend" && (
              <TrendingLineChart
                departments={breakdownPerDepartment}
                activeEmployees={activeEmployees}
              />
            )}
          </div>

          <div className="mt-6 grid grid-cols-2 gap-4">
            {newData.slice(0, 4).map((item, index) => (
              <div
                key={index}
                className="flex items-center p-3 rounded-lg border border-gray-100 hover:shadow-sm transition-all"
              >
                <div
                  className={`w-3 h-3 rounded-full mr-2 ${
                    index === 0
                      ? "bg-leechiuBlue"
                      : index === 1
                      ? "bg-leechiuOrange"
                      : index === 2
                      ? "bg-green-500"
                      : index === 3
                      ? "bg-purple-500"
                      : "bg-pink-500"
                  }`}
                ></div>
                <div className="flex-1">
                  <div className="text-xs font-medium text-gray-700 mb-1 truncate">
                    {item.name}
                  </div>
                  <div className="flex items-center justify-between">
                    <div className="w-full bg-gray-100 rounded-full h-1.5">
                      <div
                        className={`h-1.5 rounded-full ${
                          index === 0
                            ? "bg-leechiuBlue"
                            : index === 1
                            ? "bg-leechiuOrange"
                            : index === 2
                            ? "bg-green-500"
                            : index === 3
                            ? "bg-purple-500"
                            : "bg-pink-500"
                        }`}
                        style={{
                          width: `${
                            (item.value / activeEmployees.length) * 100
                          }%`,
                        }}
                      ></div>
                    </div>
                    <span className="ml-2 text-xs font-bold">
                      {item.value}{" "}
                      <span className="text-gray-500 font-normal">
                        (
                        {Math.round(
                          (item.value / activeEmployees.length) * 100
                        )}
                        %)
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* UPDATED: Employee Growth Trend with real data */}
        <div className="lg:col-span-6 bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-bold text-gray-800">
              Employee Growth Trend
            </h2>
            <div className="flex items-center space-x-2">
              <select className="bg-gray-100 border-0 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-leechiuBlue/30 text-sm">
                <option value="6m">Last 6 Months</option>
                <option value="1y">Last Year</option>
              </select>
            </div>
          </div>

          <div className="h-[250px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={employeeGrowthData}
                margin={{ top: 10, right: 20, left: 10, bottom: 10 }}
              >
                <CartesianGrid
                  strokeDasharray="3 3"
                  vertical={false}
                  stroke="#f0f0f0"
                />
                <XAxis dataKey="month" axisLine={false} tickLine={false} />
                <YAxis axisLine={false} tickLine={false} />
                <Tooltip />
                <Legend verticalAlign="top" height={20} />
                <Line
                  type="monotone"
                  dataKey="total"
                  name="Total"
                  stroke="#2563eb"
                  strokeWidth={2}
                  dot={{ r: 3 }}
                  activeDot={{ r: 5 }}
                />
                <Line
                  type="monotone"
                  dataKey="revenue"
                  name="Revenue"
                  stroke="#f97316"
                  strokeWidth={2}
                  dot={{ r: 3 }}
                />
                <Line
                  type="monotone"
                  dataKey="support"
                  name="Support"
                  stroke="#10b981"
                  strokeWidth={2}
                  dot={{ r: 3 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="mt-3 grid grid-cols-3 gap-3">
            <div className="bg-blue-50 rounded-lg p-3 border border-blue-100">
              <div className="flex items-center">
                <div className="p-1.5 rounded-full bg-blue-100 text-leechiuBlue mr-2">
                  <FiUsers size={12} />
                </div>
                <div>
                  <p className="text-xs text-gray-500">Total</p>
                  <h3 className="text-sm font-bold text-gray-800">
                    {activeEmployees.length}
                  </h3>
                </div>
                <div
                  className={`ml-auto flex items-center text-xs ${
                    parseFloat(totalGrowth) >= 0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {parseFloat(totalGrowth) >= 0 ? (
                    <FiTrendingUp className="mr-1" size={12} />
                  ) : (
                    <FiTrendingDown className="mr-1" size={12} />
                  )}
                  {Math.abs(parseFloat(totalGrowth))}%
                </div>
              </div>
            </div>

            <div className="bg-orange-50 rounded-lg p-3 border border-orange-100">
              <div className="flex items-center">
                <div className="p-1.5 rounded-full bg-orange-100 text-leechiuOrange mr-2">
                  <FiBarChart2 size={12} />
                </div>
                <div>
                  <p className="text-xs text-gray-500">Revenue</p>
                  <h3 className="text-sm font-bold text-gray-800">
                    {currentStats.revenue}
                  </h3>
                </div>
                <div
                  className={`ml-auto flex items-center text-xs ${
                    parseFloat(revenueGrowth) >= 0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {parseFloat(revenueGrowth) >= 0 ? (
                    <FiTrendingUp className="mr-1" size={12} />
                  ) : (
                    <FiTrendingDown className="mr-1" size={12} />
                  )}
                  {Math.abs(parseFloat(revenueGrowth))}%
                </div>
              </div>
            </div>

            <div className="bg-green-50 rounded-lg p-3 border border-green-100">
              <div className="flex items-center">
                <div className="p-1.5 rounded-full bg-green-100 text-green-600 mr-2">
                  <FiUser size={12} />
                </div>
                <div>
                  <p className="text-xs text-gray-500">Support</p>
                  <h3 className="text-sm font-bold text-gray-800">
                    {currentStats.support}
                  </h3>
                </div>
                <div
                  className={`ml-auto flex items-center text-xs ${
                    parseFloat(supportGrowth) >= 0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >
                  {parseFloat(supportGrowth) >= 0 ? (
                    <FiTrendingUp className="mr-1" size={12} />
                  ) : (
                    <FiTrendingDown className="mr-1" size={12} />
                  )}
                  {Math.abs(parseFloat(supportGrowth))}%
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* IMPROVED: Demographics - Now with interactive charts */}
        <div className="lg:col-span-6 bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-bold text-gray-800">
              Employee Demographics
            </h2>
            <div className="flex items-center bg-gray-100 rounded-lg p-1">
              <button
                className={`px-3 py-1.5 rounded-lg text-sm transition-all ${
                  demographicType === "gender"
                    ? "bg-white shadow-sm text-leechiuBlue"
                    : "text-gray-600"
                }`}
                onClick={() => setDemographicType("gender")}
              >
                Gender
              </button>
              <button
                className={`px-3 py-1.5 rounded-lg text-sm transition-all ${
                  demographicType === "age"
                    ? "bg-white shadow-sm text-leechiuBlue"
                    : "text-gray-600"
                }`}
                onClick={() => setDemographicType("age")}
              >
                Age
              </button>
              <button
                className={`px-3 py-1.5 rounded-lg text-sm transition-all ${
                  demographicType === "tenure"
                    ? "bg-white shadow-sm text-leechiuBlue"
                    : "text-gray-600"
                }`}
                onClick={() => setDemographicType("tenure")}
              >
                Tenure
              </button>
            </div>
          </div>

          <div className="p-4 bg-gray-50 rounded-xl">
            <DemographicsChart
              type={demographicType}
              employees={activeEmployees}
              birthDateData={activeEmployeesBirthDate}
            />
          </div>

          <div className="mt-4">
            <div className="flex items-center justify-between mb-3">
              <h3 className="text-sm font-medium text-gray-700">
                Latest Hires
              </h3>
              <button className="text-xs font-medium text-leechiuBlue">
                View all
              </button>
            </div>
            <div className="space-y-2">
              {newEmployees.slice(0, 3).map((employee, index) => (
                <div
                  key={index}
                  className="p-2 rounded-lg border border-gray-100 hover:bg-gray-50 transition-all"
                >
                  <div className="flex items-center">
                    <div className="flex-shrink-0 h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center text-sm font-medium text-gray-600">
                      {employee.firstName?.charAt(0)}
                      {employee.lastName?.charAt(0)}
                    </div>
                    <div className="ml-3">
                      <div className="text-sm font-medium text-gray-900">
                        {employee.firstName} {employee.lastName}
                      </div>
                      <div className="text-xs text-gray-500">
                        {employee.department} •{" "}
                        {new Date(employee.startDate).toLocaleDateString()}
                      </div>
                    </div>
                    <span className="ml-auto px-2 py-1 bg-green-100 text-green-800 text-xs rounded-full">
                      New
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Employee table - Keep but wrap in a scrollable container */}
        <div className="lg:col-span-8 bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-bold text-gray-800">
              Employee Directory
            </h2>
            <div className="flex space-x-2">
              <button className="px-3 py-1.5 rounded-lg bg-gray-100 text-gray-600 hover:bg-gray-200 transition-colors flex items-center text-sm">
                <FiFilter className="mr-1" size={14} />
                Filter
              </button>
              <button className="px-3 py-1.5 rounded-lg bg-gray-100 text-gray-600 hover:bg-gray-200 transition-colors flex items-center text-sm">
                <FiDownload className="mr-1" size={14} />
                Export
              </button>
              <Link
                to="/employees"
                className="px-3 py-1.5 rounded-lg bg-leechiuBlue text-white hover:bg-leechiuBlue/90 transition-colors text-sm"
              >
                View All
              </Link>
            </div>
          </div>

          <div className="overflow-x-auto rounded-lg border border-gray-100">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  <th className="px-4 py-3">Name</th>
                  <th className="px-4 py-3">Department</th>
                  <th className="px-4 py-3">Position</th>
                  <th className="px-4 py-3">Status</th>
                  <th className="px-4 py-3 text-right">Action</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {activeEmployees.slice(0, 5).map((employee, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-50 transition-colors"
                  >
                    <td className="px-4 py-3 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center text-sm font-medium text-gray-600">
                          {employee.firstName?.charAt(0)}
                          {employee.lastName?.charAt(0)}
                        </div>
                        <div className="ml-3">
                          <div className="text-sm font-medium text-gray-900">
                            {employee.firstName} {employee.lastName}
                          </div>
                          <div className="text-xs text-gray-500">
                            {employee.email || "No email"}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-800">
                      {employee.department}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-sm text-gray-800">
                      {employee.position || "Not specified"}
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap">
                      <span
                        className={`px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full
                        ${
                          employee.status === "Regular"
                            ? "bg-green-100 text-green-800"
                            : employee.status === "Probationary"
                            ? "bg-yellow-100 text-yellow-800"
                            : "bg-gray-100 text-gray-800"
                        }`}
                      >
                        {employee.status}
                      </span>
                    </td>
                    <td className="px-4 py-3 whitespace-nowrap text-right">
                      <Link
                        to={`/employees/${employee.id}`}
                        className="text-leechiuBlue hover:text-leechiuOrange text-sm"
                      >
                        View
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {activeEmployees.length > 5 && (
            <div className="mt-4 text-center">
              <Link
                to="/employees"
                className="text-leechiuBlue hover:text-leechiuOrange text-sm font-medium"
              >
                View all {activeEmployees.length} employees →
              </Link>
            </div>
          )}
        </div>

        {/* Other Reports */}
        <div className="lg:col-span-4 bg-white rounded-xl shadow-sm p-6">
          <h2 className="text-lg font-bold text-gray-800 mb-6">
            Other Reports
          </h2>
          <div className="space-y-4">
            {[
              {
                name: "Tenure Distribution",
                icon: <FiUsers />,
                color: "text-leechiuBlue",
                bgColor: "bg-leechiuBlue/10",
              },
              {
                name: "Age Group Analysis",
                icon: <FiBarChart2 />,
                color: "text-leechiuOrange",
                bgColor: "bg-leechiuOrange/10",
              },
              {
                name: "Resignation Trends",
                icon: <FiTrendingUp />,
                color: "text-red-500",
                bgColor: "bg-red-500/10",
              },
              {
                name: "Performance Metrics",
                icon: <FiPieChart />,
                color: "text-green-500",
                bgColor: "bg-green-500/10",
              },
            ].map((report, index) => (
              <div
                key={index}
                className="p-4 rounded-lg border border-gray-100 hover:border-leechiuBlue/50 hover:bg-gray-50 transition-all cursor-pointer"
              >
                <div className="flex items-center">
                  <div
                    className={`p-2 rounded-lg ${report.bgColor} ${report.color} mr-3`}
                  >
                    {report.icon}
                  </div>
                  <div>
                    <span className="font-medium text-gray-700">
                      {report.name}
                    </span>
                    <div className="text-xs text-gray-500 mt-1">
                      Last updated 2 days ago
                    </div>
                  </div>
                  <span className="ml-auto text-leechiuOrange">→</span>
                </div>
              </div>
            ))}
          </div>

          <QuickAnalytics employees={employees} />
        </div>

        {/* Add new Resignations & Hires Chart Section */}
        <div className="lg:col-span-12 bg-white rounded-xl shadow-sm p-6 mt-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-bold text-gray-800">
              Hiring & Retention Analysis
            </h2>
            <div className="flex items-center space-x-2">
              <select className="bg-gray-100 border-0 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-leechiuBlue/30 text-sm">
                <option value="12m">Last 12 Months</option>
                <option value="6m">Last 6 Months</option>
                <option value="thisYear">This Year</option>
              </select>
              <button className="bg-gray-100 p-2 rounded-lg hover:bg-gray-200 transition-colors">
                <FiDownload className="text-gray-600" />
              </button>
            </div>
          </div>

          <ResignationHiresChart allEmployees={employees} />
        </div>

        {/* Add the LeaveAnalytics component to the dashboard grid */}
        <div className="lg:col-span-12 bg-white rounded-xl shadow-sm p-6 mt-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-bold text-gray-800">Leave Analytics</h2>
            <div className="flex items-center space-x-2">
              <select className="bg-gray-100 border-0 rounded-lg px-3 py-2 focus:outline-none focus:ring-2 focus:ring-leechiuBlue/30 text-sm">
                <option value="thisYear">This Year</option>
                <option value="lastYear">Last Year</option>
              </select>
              <button className="bg-gray-100 p-2 rounded-lg hover:bg-gray-200 transition-colors">
                <FiDownload className="text-gray-600" />
              </button>
            </div>
          </div>

          <LeaveAnalytics />
        </div>

        {/* Add Workforce Forecast after LeaveAnalytics */}
        <div className="lg:col-span-12 bg-white rounded-xl shadow-sm p-6 mt-6">
          <WorkforceForecast
            leaveRequests={leaveRequests}
            activeEmployees={employees}
          />
        </div>
      </div>

      {/* Shortcuts */}
      <div className="mt-6 bg-white rounded-xl shadow-sm p-6">
        <ShortCuts />
      </div>
    </div>
  );
}

const ShortCuts = () => {
  const [activeShortCut, setActiveShortCut] = React.useState("Documents");

  const shortcuts = [
    { id: "Documents", icon: <FiFileText /> },
    { id: "People", icon: <FiUser /> },
    { id: "Others", icon: <FiMoreHorizontal /> },
  ];

  return (
    <div>
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-lg font-bold text-gray-800">Shortcuts</h2>
        <div className="bg-gray-100 rounded-lg p-1 flex">
          {shortcuts.map((item) => (
            <button
              key={item.id}
              className={`flex items-center px-4 py-2 rounded-lg transition-all ${
                activeShortCut === item.id
                  ? "bg-leechiuOrange text-white shadow-sm"
                  : "text-gray-600 hover:bg-gray-200"
              }`}
              onClick={() => setActiveShortCut(item.id)}
            >
              <span className="mr-2">{item.icon}</span>
              {item.id}
            </button>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {[1, 2, 3].map((item) => (
          <div
            key={item}
            className="p-4 rounded-lg border border-gray-100 hover:shadow-md transition-all cursor-pointer flex items-center"
          >
            <div className="bg-leechiuBlue/10 p-3 rounded-lg mr-4">
              <FiFileText className="text-leechiuBlue" />
            </div>
            <div>
              <h3 className="font-medium text-gray-800">Shortcut {item}</h3>
              <p className="text-sm text-gray-500">Quick access</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminDashboard;
