import React from 'react'

export default function BentoContainer({subtitle, label, children}) {
  return (
    <div className='border border-gray-200 rounded-lg mt-4 overflow-hidden w-full'>
        <div className='bg-blue-900 text-white font-bold p-2'>
            <div className='text-left uppercase  font-semibold'>{label}</div>
            <div className='text-xs text-left font-normal'>{subtitle}</div>
        </div>
        {children}</div>
  )
}
